import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  VStack,
  Text,
  useDisclosure,
  Heading,
  Tooltip,
  Badge,
  useColorModeValue,
  Flex,
  Avatar,
  Input,
  Select,
  InputGroup,
  InputLeftElement,
  AvatarGroup,
} from "@chakra-ui/react";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { AddIcon, ChevronDownIcon, Search2Icon } from "@chakra-ui/icons";
import CreateMyTaskModal from "../Miscellaneous/MyTasks/CreateMyTaskModal";
import { UserState } from "../../Context/UserProvider";
import { AuthState } from "../../Context/AuthProvider";
import { findName } from "../Miscellaneous/MyTasks/utils";
import ViewTaskModal from "../Miscellaneous/MyTasks/ViewTaskModal";
import { PriorityIcon } from "../Miscellaneous/Dropdowns/PriorityDropDown";
import { LayoutState } from "../../Context/LayoutProvider";
import { CheckIcon, User2Icon } from "lucide-react";

const statuses = [
  { value: "To Do", name: "toDo", color: "gray.100" },
  { value: "In Progress", name: "inProgress", color: "blue.100" },
  { value: "Review", name: "review", color: "yellow.100" },
  { value: "Done", name: "done", color: "green.100" },
  { value: "Blocked", name: "blocked", color: "red.100" },
];

const TaskCard = ({ task, organizationMembers, onOpen, setViewingTask }) => {
  const bgColor = useColorModeValue("white", "gray.700");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const hoverBgColor = useColorModeValue("gray.50", "gray.600");

  return (
    <Box
      p={2}
      shadow="md"
      borderWidth="1px"
      borderRadius="lg"
      bg={bgColor}
      mb={2}
      width="100%"
      position="relative"
      borderColor={borderColor}
      _hover={{
        bg: hoverBgColor,
        transform: "translateY(-2px)",
        transition: "all 0.2s",
        boxShadow: "lg",
      }}
      cursor="pointer"
      overflowX={"scroll"}
    >
      <VStack align="stretch" spacing={1}>
        <Box
          onClick={() => {
            setViewingTask({ ...task });
            onOpen();
          }}
        >
          <Text
            fontSize="md"
            lineHeight={1.2}
            noOfLines={3}
            overflowX={"scroll"}
            mb={3}
            maxW={"180px"}
          >
            {task.title}
          </Text>

          <Tooltip label="Milestone" placement="top">
            <Text
              ml={1}
              fontSize="xs"
              color="gray.500"
              fontWeight="medium"
              maxW={"150px"}
              isTruncated
            >
              {task.milestone.title}
            </Text>
          </Tooltip>

          <Flex justify="space-between" align="center">
            <Box flex={1} display={"flex"} alignItems={"center"}>
              <Tooltip label="Task ID" placement="top">
                <Badge fontSize="xs" px={2} py={1} borderRadius="full">
                  {task?.task_uid ? task.task_uid : task.id.slice(-5)}
                </Badge>
              </Tooltip>
            </Box>

            <Flex gap={1} align="center">
              <Tooltip label="Priority" placement="top">
                <Badge style={{ all: "unset" }} p={2}>
                  <PriorityIcon priority={task.priority} />
                </Badge>
              </Tooltip>

              <Tooltip
                label={`Duration: ${task.duration} hour`}
                placement="top"
              >
                <Flex
                  ml={3}
                  alignItems="center"
                  justifyContent="center"
                  bg="gray.100"
                  color="gray.600"
                  borderRadius="full"
                  px={2}
                  py={1}
                  minWidth="32px"
                >
                  <Text fontSize="xs" fontWeight="medium">
                    {task.duration}
                  </Text>
                </Flex>
              </Tooltip>

              <Tooltip label="Assignee" placement="top">
                <Avatar
                  name={findName(task.assigneeId, organizationMembers)}
                  size={"xs"}
                  src={
                    organizationMembers?.find(
                      (member) => member.firebaseUserId === task.assigneeId
                    )?.imageUrl
                  }
                />
              </Tooltip>
            </Flex>
          </Flex>
        </Box>
      </VStack>
    </Box>
  );
};

export default function Board({ viewingProject }) {
  const [showBoard, setShowBoard] = useState(true);
  const [tasks, setTasks] = useState([]);
  const [filteredTasks, setFilteredTasks] = useState([]);
  const [viewingTask, setViewingTask] = useState(null);
  const [filters, setFilters] = useState({
    taskQuery: "",
    assigneeIds: [],
  });
  const [assignees, setAssignees] = useState([]);
  const {
    isOpen: isCreateTaskModalOpen,
    onOpen: openCreateTaskModal,
    onClose: closeCreateTaskModal,
  } = useDisclosure();
  const {
    isOpen: isViewTaskModalOpen,
    onOpen: openViewTaskModal,
    onClose: closeViewTaskModal,
  } = useDisclosure();
  const { organizationMembers, getProjectActiveSprintTasks, updateTaskStatus } =
    UserState();
  const { user } = AuthState();
  const { screenWidth } = LayoutState();

  const bgColor = useColorModeValue("gray.50", "gray.900");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const columnBgColor = useColorModeValue("white", "gray.800");

  const isAssigneeSelected = (assignee) => {
    return filters.assigneeIds.includes(assignee.firebaseUserId);
  };

  useEffect(() => {
    const fetchProjectActiveSprintTasks = async () => {
      if (
        viewingProject?.sprints?.activeSprint &&
        viewingProject.sprints.activeSprint !== "None"
      ) {
        const activeSprintTasks = await getProjectActiveSprintTasks(
          viewingProject.id,
          viewingProject.sprints.activeSprint
        );
        setTasks(activeSprintTasks);
        setFilteredTasks(activeSprintTasks);
      } else if (viewingProject?.sprints?.activeSprint === "None") {
        console.log("There is no active sprint for this project.");
        setShowBoard(false);
      }
    };

    if (viewingProject) {
      fetchProjectActiveSprintTasks();
    }
  }, [viewingProject]);

  useEffect(() => {
    if (
      organizationMembers &&
      Array.isArray(organizationMembers) &&
      organizationMembers.length > 0
    ) {
      setAssignees(
        organizationMembers.filter(
          (member) =>
            member?.projectId === viewingProject?.id ||
            member?.projectIds?.includes(viewingProject?.id)
        )
      );
    }
  }, []);

  useEffect(() => {
    const filtered = tasks?.filter((task) => {
      const matchesQuery =
        task?.title?.toLowerCase().includes(filters.taskQuery.toLowerCase()) ||
        task?.task_uid
          ?.toLowerCase()
          .includes(filters.taskQuery.toLowerCase()) ||
        task?.description
          ?.toLowerCase()
          .includes(filters.taskQuery.toLowerCase());

      const matchesAssignee =
        filters.assigneeIds.length === 0 ||
        filters.assigneeIds.includes(task.assigneeId);

      return matchesQuery && matchesAssignee;
    });

    setFilteredTasks(filtered);
  }, [filters, tasks]);

  const onDragEnd = async (result) => {
    if (!result.destination) return;

    const { source, destination, draggableId: taskId } = result;

    const newTasks = Array.from(filteredTasks);
    const taskIndex = newTasks.findIndex((task) => task.id === taskId);

    if (taskIndex === -1) return;

    const [movedTask] = newTasks.splice(taskIndex, 1);
    newTasks.splice(destination.index, 0, movedTask);

    const newStatus = statuses[parseInt(destination.droppableId)].name;
    const prevStatus = movedTask.status;
    movedTask.status = newStatus;

    try {
      if (newStatus !== prevStatus) {
        await updateTaskStatus(movedTask, newStatus, prevStatus);
        setFilteredTasks(newTasks);
        setTasks((prevTasks) =>
          prevTasks.map((task) =>
            task.id === movedTask.id ? { ...task, status: newStatus } : task
          )
        );
      }
    } catch (err) {
      console.error("Error updating task status:", err);
    }
  };

  return showBoard ? (
    <Box
      height={"100%"}
      width="100%"
      display="flex"
      flexDirection="column"
      alignItems="center"
      p={1}
      pt={0}
    >
      <Flex
        align="center"
        justifyContent="end"
        mx={screenWidth < 700 && 4}
        mb={2}
        flexDir={"row"}
        width={"100%"}
      >
        <Flex
          gap={4}
          flex={1}
          alignItems="flex-start"
          justifyContent={"end"}
          overflowX={"scroll"}
        >
          <Box maxW="420px" overflowX="auto" _hover={{ cursor: "pointer" }}>
            <AvatarGroup size="sm" _hover={{ cursor: "pointer" }}>
              {assignees.map((assignee, index) => (
                <Tooltip
                  borderRadius={"full"}
                  key={index}
                  label={`${assignee?.firstName?.toUpperCase()} ${assignee?.lastName?.toUpperCase()}`}
                  placement="bottom"
                >
                  <Box position={"relative"} display={"inline-block"}>
                    <Avatar
                      key={index}
                      sx={{ height: "2.5rem", width: "2.5rem" }}
                      name={`${assignee?.firstName} ${assignee?.lastName}`}
                      src={assignee?.imageUrl || ""}
                      title={`${assignee?.firstName} ${assignee?.lastName}`}
                      onClick={() => {
                        setFilters((prev) => {
                          const newAssigneeIds = prev.assigneeIds.includes(
                            assignee?.firebaseUserId
                          )
                            ? prev.assigneeIds.filter(
                                (firebaseUserId) =>
                                  firebaseUserId !== assignee?.firebaseUserId
                              )
                            : [...prev.assigneeIds, assignee?.firebaseUserId];

                          return { ...prev, assigneeIds: newAssigneeIds };
                        });
                      }}
                    />
                    {isAssigneeSelected(assignee) && (
                      <Box
                        position="absolute"
                        bottom="0"
                        right="0"
                        bg="green.500"
                        borderRadius="full"
                        width="14px"
                        height="14px"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <CheckIcon color="white" boxSize="8px" />
                      </Box>
                    )}
                  </Box>
                </Tooltip>
              ))}
            </AvatarGroup>
          </Box>
          <InputGroup width={screenWidth < 850 ? "200px" : "350px"}>
            <InputLeftElement pointerEvents="none">
              <Search2Icon color="gray.300" />
            </InputLeftElement>
            <Input
              placeholder="Search tasks"
              value={filters.taskQuery}
              onChange={(e) =>
                setFilters((prev) => ({ ...prev, taskQuery: e.target.value }))
              }
            />
          </InputGroup>
        </Flex>
        <Button
          colorScheme="blue"
          leftIcon={<AddIcon />}
          onClick={openCreateTaskModal}
          // sx={{ height: "2rem", width: "1.5rem" }}
          ml={2}
          minW={"80px"}
          mb={2}
        >
          Task
        </Button>
      </Flex>
      <DragDropContext onDragEnd={onDragEnd}>
        <Box
          width="100%"
          height="calc(100% - 80px)"
          mx={2}
          overflowX={"scroll"}
        >
          <PerfectScrollbar
            options={{
              suppressScrollY: true,
              useBothWheelAxes: false,
            }}
            style={{
              width: "100%",
              height: `calc(100% - 14px)`,
              paddingRight: "0px",
            }}
          >
            <Flex
              alignItems="flex-start"
              justifyContent={screenWidth > 1600 && "center"}
              align={"center"}
              minWidth="100%"
              pb={2}
            >
              {statuses.map((status, columnIndex) => (
                <Droppable
                  key={status.name}
                  droppableId={columnIndex.toString()}
                >
                  {(provided, snapshot) => (
                    <VStack
                      maxWidth="270px"
                      minWidth="270px"
                      height="calc(100vh - 230px)"
                      borderWidth="1px"
                      borderColor={borderColor}
                      bg={bgColor}
                      p={2}
                      pb={0}
                      borderRadius="md"
                      borderBottomRadius={"0"}
                      boxShadow="md"
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      flex={1}
                      mx={"8px"}
                      transition="background-color 0.2s"
                      bgGradient={
                        snapshot.isDraggingOver
                          ? `linear(to-b, ${status.color}, ${columnBgColor})`
                          : undefined
                      }
                    >
                      <Heading
                        size="sm"
                        fontSize={"1em"}
                        mb={2}
                        px={2}
                        color="#4a2a5a"
                        textAlign={"left"}
                        width={"100%"}
                      >
                        {status.value.toUpperCase()}
                      </Heading>
                      <Box
                        position="relative"
                        height="calc(100% - 40px)"
                        width="100%"
                      >
                        <PerfectScrollbar
                          options={{
                            suppressScrollX: true,
                            minScrollbarLength: 20,
                          }}
                          style={{
                            width: "calc(100% + 8px)",
                            height: "100%",
                            paddingRight: "0px",
                          }}
                        >
                          <Box pr={2}>
                            {filteredTasks
                              ?.filter((task) => task.status === status.name)
                              ?.map((task, index) => (
                                <Draggable
                                  key={task.id}
                                  draggableId={task.id}
                                  index={index}
                                >
                                  {(provided, snapshot) => (
                                    <Box
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      opacity={snapshot.isDragging ? 0.8 : 1}
                                      transform={
                                        snapshot.isDragging
                                          ? "rotate(3deg)"
                                          : ""
                                      }
                                      transition="all 0.2s"
                                    >
                                      <TaskCard
                                        task={task}
                                        organizationMembers={
                                          organizationMembers
                                        }
                                        setViewingTask={setViewingTask}
                                        onOpen={openViewTaskModal}
                                        user={user}
                                      />
                                    </Box>
                                  )}
                                </Draggable>
                              ))}
                            {provided.placeholder}
                          </Box>
                          <Box height={"200px"} />
                        </PerfectScrollbar>
                      </Box>
                    </VStack>
                  )}
                </Droppable>
              ))}
            </Flex>
          </PerfectScrollbar>
        </Box>
      </DragDropContext>
      {isCreateTaskModalOpen && (
        <CreateMyTaskModal
          isOpen={isCreateTaskModalOpen}
          onClose={closeCreateTaskModal}
          setAllTasks={setTasks}
          allTasks={tasks}
          project={viewingProject}
          providedSprintId={viewingProject?.sprints?.activeSprint}
        />
      )}
      {isViewTaskModalOpen && (
        <ViewTaskModal
          task={viewingTask}
          setTask={setViewingTask}
          isOpen={isViewTaskModalOpen}
          onClose={() => {
            setViewingTask(null);
            closeViewTaskModal();
          }}
          allTasks={tasks}
          setAllTasks={setTasks}
        />
      )}
    </Box>
  ) : (
    <Flex
      height="100%"
      width="100%"
      direction="column"
      align="center"
      justify="center"
      bg={"white"}
    >
      <Text fontSize="xl" fontWeight="bold" mb={3}>
        No Active Sprints
      </Text>
      <Text textAlign={"center"}>
        Start a new Sprint to view the Sprint Board.
      </Text>
    </Flex>
  );
}
