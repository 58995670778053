import React, { useEffect, useState } from "react";
import { UserState } from "../../Context/UserProvider";
import {
  Box,
  Button,
  Text,
  Textarea,
  VStack,
  useToast,
  useColorModeValue,
  InputGroup,
  InputRightElement,
  IconButton,
} from "@chakra-ui/react";
import { CopyIcon } from "@chakra-ui/icons";

export default function Unverified({ viewingOrganization }) {
  const { generateDNSVerificationCode, verifyDomainTXTRecord } = UserState();
  const [code, setCode] = useState(
    viewingOrganization?.verification?.code || ""
  );
  const [isExpired, setIsExpired] = useState(false);
  const toast = useToast();

  const borderColor = useColorModeValue("gray.200", "gray.600");
  const bgColor = useColorModeValue("white", "gray.700");
  const textareaBg = useColorModeValue("gray.50", "gray.600");

  const copyToClipboard = () => {
    navigator.clipboard.writeText(code).then(
      () => {
        toast({
          title: "Copied to clipboard",
          status: "success",
          duration: 2000,
          isClosable: true,
        });
      },
      (err) => {
        console.error("Could not copy text: ", err);
        toast({
          title: "Failed to copy",
          status: "error",
          duration: 2000,
          isClosable: true,
        });
      }
    );
  };

  const verifyDomain = async () => {
    try {
      const verified = await verifyDomainTXTRecord(
        viewingOrganization?.domain,
        viewingOrganization?.id
      );
      if (verified === "Domain verified successfully") {
        toast({
          title: "Domain verified",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } else {
        throw new Error(verified?.response?.data?.error);
      }
    } catch (error) {
      if (error.message) {
        toast({
          title: error.message,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Couldn't find TXT record",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    }
  };

  const generateCode = async () => {
    try {
      const generatedCode = await generateDNSVerificationCode(
        viewingOrganization?.id,
        viewingOrganization?.domain
      );
      setCode(generatedCode);
      toast({
        title: "Verification code generated",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error generating verification code:", error);
      toast({
        title: "Error generating verification code",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    const now = new Date();
    const expiry = new Date(viewingOrganization?.verification?.expirationTime);

    setIsExpired(now > expiry);
    setCode(viewingOrganization?.verification?.code || "");
  }, [viewingOrganization]);

  return (
    <Box
      minH="400px"
      width="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
      overflowY={"auto"}
      my={4}
    >
      <VStack
        spacing={6}
        borderRadius="md"
        border="1px solid"
        borderColor={borderColor}
        p={6}
        width="90%"
        maxWidth="600px"
        bg={bgColor}
        boxShadow="md"
      >
        {code && !isExpired ? (
          <>
            <Text fontSize="lg" textAlign="center">
              Add a TXT record to <strong>{viewingOrganization?.domain}</strong>{" "}
              with the following value:
            </Text>
            <InputGroup size="md">
              <Textarea
                value={code}
                readOnly
                height="30px"
                textAlign="center"
                fontWeight="bold"
                fontSize="md"
                bg={textareaBg}
                pr="4.5rem"
              />
              <InputRightElement width="4.5rem">
                <IconButton
                  h="1.75rem"
                  size="sm"
                  onClick={copyToClipboard}
                  aria-label="Copy to clipboard"
                  icon={<CopyIcon />}
                />
              </InputRightElement>
            </InputGroup>
            <Text fontSize="md" textAlign="center">
              This code will be valid for the next{" "}
              {Math.floor(
                (new Date(viewingOrganization?.verification?.expirationTime) -
                  new Date()) /
                  3600000
              ) || 60}{" "}
              hours and{" "}
              {Math.floor(
                ((new Date(viewingOrganization?.verification?.expirationTime) -
                  new Date()) /
                  60000) %
                  60
              ) || 0}{" "}
              minutes
            </Text>
            <Button colorScheme="green" onClick={verifyDomain}>
              Verify Domain
            </Button>
            <Box width={"100%"}>
              <b>Please Note:</b>
              <Text ml={5} fontSize="sm">
                - It may take upto 48 hours for TXT record to reflect on your
                domain.
              </Text>
            </Box>
          </>
        ) : (
          <>
            <Text fontSize="lg" textAlign="center">
              You are required to verify your organization domain{" "}
              <b>{viewingOrganization?.domain}</b> to start using <b>PMBot.</b>
            </Text>
            <Button
              onClick={generateCode}
              colorScheme="blue"
              width="100%"
              maxWidth="240px"
            >
              Generate Verification Code
            </Button>
          </>
        )}
      </VStack>
    </Box>
  );
}
