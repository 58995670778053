import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
  useColorModeValue,
  Avatar,
} from "@chakra-ui/react";
import { AddIcon, DeleteIcon } from "@chakra-ui/icons";
import { UserState } from "../../../../Context/UserProvider";
import { LayoutState } from "../../../../Context/LayoutProvider";
import InviteMemberModal from "./InviteMemberModal";

const AllMembers = ({ viewingProject }) => {
  const {
    organizationMembers,
    selectedOrganization,
    projects,
    removeMembersFromProject,
  } = UserState();
  const { screenWidth } = LayoutState();
  const toast = useToast();
  const {
    isOpen: isInviteMembersModalOpen,
    onOpen: openInviteMembersModal,
    onClose: closeInviteMembersModal,
  } = useDisclosure();

  const [members, setMembers] = useState([]);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [clickedMember, setClickedMember] = useState(null);
  const [allSelected, setAllSelected] = useState(false);

  const bgColor = useColorModeValue("white", "gray.800");
  const hoverBgColor = useColorModeValue("gray.100", "gray.700");
  const selectedBgColor = useColorModeValue("purple.50", "purple.900");
  const textColor = useColorModeValue("gray.800", "white");
  const headerBgColor = useColorModeValue("gray.50", "gray.700");

  const isSelected = (member) =>
    selectedMembers.some(
      (selectedMember) =>
        selectedMember.firebaseUserId === member.firebaseUserId
    );

  const selectUnselectAll = () => {
    setSelectedMembers(allSelected ? [] : [...members]);
  };

  const removeMembers = async () => {
    try {
      await removeMembersFromProject(selectedMembers, viewingProject.id);
      setSelectedMembers([]);
      toast({
        title: "Members removed successfully",
        status: "success",
        duration: 2000,
        isClosable: true,
      });
    } catch (error) {
      console.error(error);
      toast({
        title: "Error removing members",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    setAllSelected(
      selectedMembers.length === members.length && members.length > 0
    );
  }, [selectedMembers, members]);

  useEffect(() => {
    const projMembers = [];
    if (organizationMembers && viewingProject?.members) {
      for (const memberId of viewingProject.members) {
        const projMember = organizationMembers.find(
          (member) => member.firebaseUserId === memberId
        );
        if (projMember?.firebaseUserId) {
          projMembers.push(projMember);
        }
      }
      setMembers(projMembers);
    }
  }, [organizationMembers, viewingProject, projects]);

  return (
    <Box bg={bgColor} borderRadius="lg" p={1} overflowY={"scroll"}>
      {isInviteMembersModalOpen && (
        <InviteMemberModal
          isOpen={isInviteMembersModalOpen}
          onClose={closeInviteMembersModal}
          viewingProject={viewingProject}
        />
      )}
      <Flex justifyContent="right" alignItems="center" mb={2}>
        {selectedMembers.length > 0 ? (
          <Button
            leftIcon={<DeleteIcon />}
            bg="red.500"
            color="white"
            size="sm"
            onClick={removeMembers}
            _hover={{ bg: "red.600" }}
          >
            Remove Members
          </Button>
        ) : (
          <Button
            leftIcon={<AddIcon />}
            bg="#a67db7"
            color="white"
            size="sm"
            onClick={openInviteMembersModal}
            _hover={{ bg: "#956ba6" }}
          >
            Invite Members
          </Button>
        )}
      </Flex>
      {members && members.length > 0 ? (
        <TableContainer>
          <Table variant="simple">
            <Thead bg={headerBgColor}>
              <Tr>
                <Th width="40px">
                  <Checkbox
                    isChecked={allSelected}
                    onChange={selectUnselectAll}
                    colorScheme="purple"
                  />
                </Th>
                <Th>Member</Th>
                <Th>Email</Th>
                {/* <Th>Role</Th> */}
              </Tr>
            </Thead>
            <Tbody>
              {members?.map((member) => (
                <Tr
                  key={member.firebaseUserId}
                  onClick={() => setClickedMember(member)}
                  bg={isSelected(member) ? selectedBgColor : "transparent"}
                  _hover={{ bg: hoverBgColor, cursor: "pointer" }}
                  transition="background-color 0.2s"
                >
                  <Td>
                    <Checkbox
                      isChecked={isSelected(member)}
                      colorScheme="purple"
                      onChange={(e) => {
                        e.stopPropagation();
                        setSelectedMembers(
                          isSelected(member)
                            ? selectedMembers.filter(
                                (m) =>
                                  m.firebaseUserId !== member.firebaseUserId
                              )
                            : [...selectedMembers, member]
                        );
                      }}
                      onClick={(e) => e.stopPropagation()}
                    />
                  </Td>
                  <Td
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"flex-start"}
                    gap={3}
                  >
                    <Avatar
                      size="xs"
                      name={`${member?.firstName} ${member?.lastName}`}
                      src={member?.imageUrl || ""}
                    />
                    {`${member?.firstName?.toUpperCase()} ${member?.lastName?.toUpperCase()}`}
                  </Td>
                  <Td>{member?.email?.toLowerCase() || ""}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      ) : (
        <Text textAlign="center" color={textColor} fontSize="lg" mt={8}>
          No Members in this Project
        </Text>
      )}
    </Box>
  );
};

export default AllMembers;
