"use client";

import {
  ModalOverlay,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  useToast,
  Button,
  VStack,
  Textarea,
  Box,
  Flex,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { UserState } from "../../../../Context/UserProvider";
import axios from "axios";
import { LayoutState } from "../../../../Context/LayoutProvider";
import ProjectMembersDropDown from "../../Dropdowns/ProjectMembersDropDown";
import { AuthState } from "../../../../Context/AuthProvider";
import { PROJ_BASE_URL as baseUrl } from "../../../../baseurls";

const CreateProjectsModal = ({ isOpen, onClose }) => {
  const { setProjects, selectedOrganization, createProject } = UserState();
  const { user, accessToken } = AuthState();
  const { isPhone } = LayoutState();
  const [projectName, setProjectName] = useState("");
  const [description, setDescription] = useState("");
  const [members, setMembers] = useState([]);
  const toast = useToast();

  const handleCreateNewProject = async (e) => {
    if (!projectName || !description) {
      toast({
        title: "Insufficient data",
        description: "All fields are required to create a new project.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    if (selectedOrganization.members[user.firebaseUserId] !== "Admin") {
      toast({
        title: "Cannot create project.",
        description: "Only Organization Admins can create new projects.",
        status: "warning",
        duration: 2500,
        isClosable: true,
      });
      return;
    }

    try {
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      };

      const requestData = {
        projectName,
        description,
        orgId: selectedOrganization.id,
        members:
          members && members.length > 0
            ? [...members, user].map((member) => member.firebaseUserId)
            : [user.firebaseUserId],
      };

      const newProject = await createProject(requestData);

      if (!newProject?.id) {
        toast({
          title: "Error occured.",
          description: "Project creation failed.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }

      toast({
        title: "Project created successfully.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      onClose();
    } catch (err) {
      toast({
        title: "Error creating project",
        description: err.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    if (isOpen && !selectedOrganization) {
      toast({
        title: "Cannot create project",
        description:
          "An organization must be selected before creating a new project",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      onClose();
    }
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent
        maxWidth={isPhone ? "95%" : "500px"}
        margin="10px"
        borderRadius="xl"
        boxShadow="xl"
      >
        <ModalHeader
          bg="gray.50"
          borderTopRadius="xl"
          borderBottom="1px"
          borderColor="gray.200"
          py={4}
        >
          <Text fontSize="xl" fontWeight="bold">
            Create New Project
          </Text>
        </ModalHeader>
        <ModalCloseButton zIndex={2} />
        <ModalBody py={6}>
          <VStack spacing={5}>
            <FormControl>
              <FormLabel fontWeight="medium">Project Name</FormLabel>
              <Input
                value={projectName}
                onChange={(e) => setProjectName(e.target.value)}
                placeholder="Enter project name"
                borderRadius="md"
              />
            </FormControl>
            <FormControl>
              <FormLabel fontWeight="medium">Description</FormLabel>
              <Textarea
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                placeholder="Enter project description"
                borderRadius="md"
                rows={4}
              />
            </FormControl>
          </VStack>
        </ModalBody>

        <ModalFooter borderTop="1px" borderColor="gray.200" py={4}>
          <Button
            colorScheme="blue"
            onClick={handleCreateNewProject}
            borderRadius="md"
            px={8}
          >
            Create Project
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CreateProjectsModal;
