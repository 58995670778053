import { createContext, useContext } from "react";
import useNotifications from "../hooks/useNotifications";

const ServiceContext = createContext();

const ServiceProvider = ({ children }) => {
  const {
    notifications,
    isLoading,
    hasMore,
    hasUnreadNotifications,
    handleLoadMore,
    markAllNotificationsAsRead,
    sendNotification,
    setHasUnreadNotifications,
  } = useNotifications();

  return (
    <ServiceContext.Provider
      value={{
        notifications,
        isLoading,
        hasMore,
        hasUnreadNotifications,
        handleLoadMore,
        sendNotification,
        markAllNotificationsAsRead,
        setHasUnreadNotifications,
      }}
    >
      {children}
    </ServiceContext.Provider>
  );
};

export const ServiceState = () => {
  return useContext(ServiceContext);
};

export default ServiceProvider;
