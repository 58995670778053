import React, { useEffect, useState } from "react";
import {
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { DeleteIcon } from "@chakra-ui/icons";
import { LayoutState } from "../../Context/LayoutProvider";
import { UserState } from "../../Context/UserProvider";
import { useNavigate } from "react-router-dom";
import OrganizationHeading from "./OrganizationHeading";

const UpdateOrganizationModal = ({ isOpen, onClose }) => {
  const { screenWidth } = LayoutState();
  const {
    selectedOrganization,
    reloadOrganizations,
    updateOrganization,
    deleteOrganization: deleteViewingOrganization,
    setSelectedOrganization: setViewingOrganization,
  } = UserState();
  const [deleteClicked, setDeleteClicked] = useState(false);
  const [name, setName] = useState(selectedOrganization?.name || "");
  const [domain, setDomain] = useState(selectedOrganization?.domain || "");
  const [pmbotName, setPmbotName] = useState(
    selectedOrganization?.pmbotName || ""
  );
  const navigate = useNavigate();
  const toast = useToast();

  const deleteOrganization = async () => {
    if (selectedOrganization.isPersonal) {
      toast({ title: "Cannot delete No Organization." });
      return;
    }
    try {
      await deleteViewingOrganization(selectedOrganization.id);
      reloadOrganizations();
      navigate("/dashboard");
      onClose();
    } catch (error) {
      console.error(error);
    }
  };

  const updateOrganizationData = async () => {
    try {
      await updateOrganization(
        selectedOrganization.id,
        name,
        domain,
        pmbotName
      );
      setViewingOrganization((org) => ({ ...org, name, domain, pmbotName }));
      reloadOrganizations();
    } catch (error) {
      toast({
        title: "Failed to save organization update. Please Try again later.",
      });
      setName(selectedOrganization.name);
      setDomain(selectedOrganization.domain);
      setPmbotName(selectedOrganization.pmbotName);
      console.error(error);
    }
  };

  const modifiedOnClose = () => {
    try {
      if (
        name !== selectedOrganization.name ||
        domain !== selectedOrganization.domain ||
        pmbotName !== selectedOrganization.pmbotName
      ) {
        updateOrganizationData();
      }
    } catch (error) {
      console.error(error);
    } finally {
      onClose();
    }
  };

  useEffect(() => {
    if (selectedOrganization) {
      setName(selectedOrganization.name);
      setDomain(selectedOrganization.domain);
      setPmbotName(selectedOrganization.pmbotName);
    }
  }, [selectedOrganization]);

  return (
    <Modal isOpen={isOpen} onClose={modifiedOnClose} isCentered>
      <ModalOverlay />
      <ModalContent
        maxWidth={screenWidth < 550 ? "95%" : "500px"}
        bgColor={deleteClicked ? "#463a3a" : "white"}
        color={deleteClicked ? "white" : "black"}
      >
        <ModalHeader>
          {deleteClicked
            ? "Confirm Organization Deletion"
            : "Update Organization"}
        </ModalHeader>
        <ModalBody>
          {deleteClicked ? (
            <>
              <Heading size={"md"} marginTop={3}>
                Are you sure about deleting your organization?
              </Heading>
              <Text marginTop={3}>This action is irreversible.</Text>
            </>
          ) : (
            <VStack gap={3}>
              <OrganizationHeading
                organization={selectedOrganization}
                setOrganization={setViewingOrganization}
              />
              <FormControl isReadOnly={selectedOrganization?.isPersonal}>
                <FormLabel>Organization Name</FormLabel>
                <Input
                  value={name}
                  type="text"
                  onChange={(e) => setName(e.target.value)}
                />
              </FormControl>
              <FormControl isReadOnly={selectedOrganization?.isPersonal}>
                <FormLabel>Organization Domain</FormLabel>
                <Input
                  value={domain}
                  type="text"
                  onChange={(e) => setDomain(e.target.value)}
                />
                <FormHelperText>
                  Example: hello.com, pmbot.app, lumiorc.com
                </FormHelperText>
              </FormControl>
              <FormControl>
                <FormLabel>PM-Bot Name</FormLabel>
                <Input
                  value={pmbotName}
                  type="text"
                  onChange={(e) => setPmbotName(e.target.value)}
                />
                <FormHelperText>
                  You can choose a custom name for your Organization's PM-Bot.
                </FormHelperText>
              </FormControl>
            </VStack>
          )}
        </ModalBody>

        <ModalFooter>
          <Button
            mr={3}
            onClick={
              !deleteClicked
                ? () => {
                    if (selectedOrganization.isPersonal) {
                      toast({ title: "Cannot delete No Organization." });
                      return;
                    }
                    setDeleteClicked(true);
                  }
                : () => setDeleteClicked(false)
            }
            color={!deleteClicked ? "grey" : "white"}
            colorScheme={deleteClicked && "blue"}
            leftIcon={!deleteClicked && <DeleteIcon />}
          >
            {!deleteClicked ? "Delete" : "Back"}
          </Button>
          <Button
            colorScheme={!deleteClicked && "blue"}
            color={deleteClicked ? "grey" : "white"}
            mr={3}
            onClick={!deleteClicked ? modifiedOnClose : deleteOrganization}
            leftIcon={deleteClicked && <DeleteIcon />}
          >
            {!deleteClicked
              ? name !== selectedOrganization.name ||
                domain !== selectedOrganization.domain
                ? "Save"
                : "Close"
              : "Confirm"}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default UpdateOrganizationModal;
