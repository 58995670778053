import {
  Avatar,
  Box,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Link,
  useToast,
} from "@chakra-ui/react";
import React, { useState, useRef, useEffect } from "react";
import { IoIosSend, IoMdDocument } from "react-icons/io";
import { AuthState } from "../../../Context/AuthProvider";
import { LayoutState } from "../../../Context/LayoutProvider";
import { UserState } from "../../../Context/UserProvider";
import { AttachmentIcon, SmallCloseIcon, SpinnerIcon } from "@chakra-ui/icons";

const AddComment = ({ setTask }) => {
  const [loading, setLoading] = useState(false);
  const [newComment, setNewComment] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const { screenWidth } = LayoutState();
  const { user } = AuthState();
  const { addTaskComment, uploadCommentAttachment } = UserState();
  const attachmentRef = useRef();
  const toast = useToast();

  const ALLOWED_EXTENSIONS = ["png", "jpg", "jpeg", "gif", "pdf"];
  const MAX_FILE_SIZE = 16 * 1024 * 1024; // 16MB

  const onAttachmentIconClicked = (e) => {
    attachmentRef?.current?.click();
  };

  const removeAttachment = (e) => {
    setSelectedFile(null);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (!file) return;

    const fileExtension = file.name.split(".").pop().toLowerCase();
    if (!ALLOWED_EXTENSIONS.includes(fileExtension)) {
      toast({
        title: "Invalid file type.",
        description: `Allowed file types are: ${ALLOWED_EXTENSIONS.join(
          ", "
        )}.`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    if (file.size > MAX_FILE_SIZE) {
      toast({
        title: "File is too large.",
        description: `Maximum file size is ${
          MAX_FILE_SIZE / (1024 * 1024)
        } MB.`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    setSelectedFile(file);
    // Further processing if needed
  };

  const handleCommentSubmit = async (e) => {
    try {
      setLoading(true);
      const uploadedComment = await addTaskComment(newComment);
      const commentId = uploadedComment.id;

      if (selectedFile) {
        await uploadCommentAttachment(selectedFile, commentId);
      }

      setTask((viewingTask) => ({
        ...viewingTask,
        commentsCount: viewingTask?.commentsCount + 1,
      }));

      setNewComment("");
      setSelectedFile(null);
    } catch (error) {
      // console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!selectedFile) {
      return;
    }

    // console.log(selectedFile);
  }, [selectedFile]);

  return (
    <Box display={"flex"} alignItems={"center"}>
      <Input
        ref={attachmentRef}
        type="file"
        accept={ALLOWED_EXTENSIONS.map((ext) => `.${ext}`).join(",")}
        onChange={handleFileChange}
        display="none"
        id="attachment-upload"
      />
      <Avatar
        src={user.imageUrl}
        name={user.firstName + " " + user.lastName}
        size={screenWidth < 460 ? "sm" : "md"}
      />
      <Box
        width={"100%"}
        display={"flex"}
        flexDir={"column"}
        justifyContent={"start"}
        gap={0}
      >
        <Box
          display={selectedFile ? "block" : "none"}
          marginLeft={"4px"}
          bgColor={"#eaeaea"}
          padding={1}
          marginBottom={0}
          borderRadius={"10px"}
          borderBottomRadius={0}
          paddingLeft={2}
          width={"90%"}
        >
          {selectedFile &&
            (selectedFile.type.includes("image/") ? (
              <Box display={"flex"}>
                <Image
                  src={URL.createObjectURL(selectedFile)}
                  maxHeight={"50px"}
                />
                <Box
                  height={"100%"}
                  flex={1}
                  display={"flex"}
                  justifyContent={"end"}
                  alignItems={"start"}
                >
                  <SmallCloseIcon
                    _hover={{ cursor: "pointer" }}
                    onClick={removeAttachment}
                  />
                </Box>
              </Box>
            ) : (
              <Box display={"flex"} alignItems={"center"}>
                <IoMdDocument />
                <Link href={URL.createObjectURL(selectedFile)}>
                  {selectedFile.name}
                </Link>
                <Box
                  height={"100%"}
                  flex={1}
                  display={"flex"}
                  justifyContent={"end"}
                  alignItems={"start"}
                >
                  <SmallCloseIcon
                    _hover={{ cursor: "pointer" }}
                    onClick={removeAttachment}
                  />
                </Box>
              </Box>
            ))}
        </Box>
        <Box display={"flex"} flexDir={"row"} width={"100%"}>
          <InputGroup padding={2} paddingTop={0} margin={0} marginTop={1}>
            <InputLeftElement
              display={"flex"}
              alignItems={"center"}
              height={"100%"}
              children={<AttachmentIcon />}
              justifyContent={"center"}
              marginLeft={2}
              _hover={{ cursor: "pointer" }}
              onClick={onAttachmentIconClicked}
            >
              <AttachmentIcon />
            </InputLeftElement>
            <Input
              bgColor={"white"}
              value={newComment}
              placeholder="Comment"
              border={"2px solid grey"}
              onChange={(e) => setNewComment(e.target.value)}
            />
          </InputGroup>
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            borderRadius={"50%"}
            // bgColor={"blue"}
            overflow={"none"}
            // padding={1}
            _hover={{ cursor: "pointer" }}
          >
            {!loading ? (
              <IoIosSend
                color="blue"
                fontSize={"2rem"}
                onClick={handleCommentSubmit}
              />
            ) : (
              <SpinnerIcon />
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default AddComment;
