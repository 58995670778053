import React, { useEffect, useState } from "react";
import {
  Box,
  Text,
  useDisclosure,
  useToast,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  Avatar,
  Flex,
  useColorModeValue,
  Divider,
} from "@chakra-ui/react";
import { ChevronDownIcon, AddIcon } from "@chakra-ui/icons";
import { useLocation, useNavigate } from "react-router-dom";
import { LayoutState } from "../../../Context/LayoutProvider";
import { UserState } from "../../../Context/UserProvider";
import CreateNewOrganizationModal from "./CreateNewOrganizationModal";

export default function Organization({ isSidebar }) {
  const { isPhone, screenWidth } = LayoutState();
  const {
    organizations,
    user,
    selectedOrganization,
    setSelectedOrganization,
    selectOrganization,
  } = UserState();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [dropDownOrgs, setDropDownOrgs] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const toast = useToast();

  const bgColor = useColorModeValue("white", "gray.800");
  const hoverBgColor = useColorModeValue("gray.50", "gray.700");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const menuItemHoverBg = useColorModeValue("blue.50", "blue.900");
  const createOrgBg = useColorModeValue("green.50", "green.900");
  const createOrgColor = useColorModeValue("green.600", "green.200");

  const handleOrganizationClick = () => {
    if (selectedOrganization?.members[user.firebaseUserId] === "Admin") {
      navigate(`/organizations/${selectedOrganization?.id}`);
    } else {
      toast({
        title: "You are not authorized to view this page.",
        status: "warning",
        duration: 2000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    const orgs = organizations?.filter(
      (org) => org?.id !== selectedOrganization?.id
    );
    console.log(selectedOrganization);

    setDropDownOrgs(orgs);
  }, [selectedOrganization, organizations]);

  return (
    organizations &&
    organizations?.length > 0 && (
      <>
        <CreateNewOrganizationModal isOpen={isOpen} onClose={onClose} />
        <Box
          bg={bgColor}
          borderRadius="md"
          boxShadow="sm"
          border="1px solid"
          borderColor={borderColor}
          transition="all 0.3s"
          _hover={{ boxShadow: "md" }}
          width={isPhone ? "100%" : "auto"}
        >
          <Flex alignItems="center">
            <Menu>
              <MenuButton
                as={Button}
                rightIcon={<ChevronDownIcon />}
                variant="ghost"
                size="sm"
                fontWeight="normal"
                _hover={{ bg: hoverBgColor }}
                _active={{ bg: hoverBgColor }}
                overflow="hidden"
                textOverflow="ellipsis"
                whiteSpace="nowrap"
                maxWidth={isPhone ? "80px" : "100px"}
              >
                {screenWidth < 780 && "Orgs"}
              </MenuButton>
              <MenuList maxHeight="300px" overflowY="auto">
                <MenuItem onClick={onOpen} width={"100%"} py={0}>
                  <Box
                    display={"flex"}
                    px={3}
                    py={2}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    bgColor={"blue.500"}
                    borderRadius={"lg"}
                    width={"100%"}
                    color={"white"}
                    _hover={{ cursor: "pointer", opacity: 0.8 }}
                  >
                    <AddIcon boxSize={3} />
                    <Text fontWeight="sm">Create Organization</Text>
                  </Box>
                </MenuItem>

                <Divider my={2} />

                {dropDownOrgs.map((org) => (
                  <MenuItem
                    key={org.id}
                    onClick={() => {
                      selectOrganization(org.id);
                      if (location.pathname.includes("organizations")) {
                        navigate(`/organizations/${org.id}`);
                      }
                      onClose();
                    }}
                    _hover={{ bg: menuItemHoverBg }}
                    p={3}
                  >
                    <Flex alignItems="center" gap={2}>
                      <Avatar size="xs" name={org.name} src={org?.logoUrl} />
                      <Text isTruncated>{org.name}</Text>
                    </Flex>
                  </MenuItem>
                ))}
              </MenuList>
            </Menu>
            <Box
              flex={1}
              py={2}
              px={3}
              onClick={handleOrganizationClick}
              _hover={{ bg: hoverBgColor, cursor: "pointer" }}
              transition="background-color 0.2s"
              width={"100%"}
              overflow={"scroll"}
            >
              <Flex alignItems="center" gap={2} overflowX={"hidden"}>
                <Avatar
                  size="xs"
                  name={selectedOrganization?.name}
                  src={selectedOrganization?.logoUrl}
                />
                <Text
                  fontSize={isPhone ? "sm" : "md"}
                  overflowX={"scroll"}
                  fontWeight="medium"
                  whiteSpace={"nowrap"}
                >
                  {selectedOrganization?.name}
                </Text>
              </Flex>
            </Box>
          </Flex>
        </Box>
      </>
    )
  );
}
