import React, { useEffect, useState } from "react";
import {
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { DeleteIcon } from "@chakra-ui/icons";
import { LayoutState } from "../../../../Context/LayoutProvider";
import { UserState } from "../../../../Context/UserProvider";
import { useNavigate } from "react-router-dom";

const UpdateProjectModal = ({ isOpen, onClose, viewingProject }) => {
  const { screenWidth } = LayoutState();
  const {
    deleteProject: deleteViewingProject,
    updateProject: updateViewingProject,
  } = UserState();
  const [deleteClicked, setDeleteClicked] = useState(false);
  const [proAcronym, setProAcronym] = useState(
    viewingProject?.proAcronym || ""
  );
  const [description, setDescription] = useState(
    viewingProject?.description || ""
  );
  const [projectName, setProjectName] = useState(
    viewingProject?.projectName || ""
  );
  const navigate = useNavigate();
  const toast = useToast();

  const deleteProject = async () => {
    try {
      await deleteViewingProject(viewingProject.id);
      navigate("/dashboard");
      toast({
        title: "Project deleted successfully.",
      });
      onClose();
    } catch (error) {
      console.error(error);
    }
  };

  const updateProejctData = async () => {
    try {
      const updatedProject = await updateViewingProject(
        {
          projectName:
            projectName === viewingProject?.projectName ? "" : projectName,
          description:
            description === viewingProject?.description ? "" : description,
          proAcronym:
            proAcronym === viewingProject?.proAcronym ? "" : proAcronym,
        },
        viewingProject.id
      );

      if (!updatedProject) {
        toast({
          title: "Failed to update project.",
        });
      } else {
        toast({
          title: "Project updated successfully.",
        });
      }
    } catch (error) {
      toast({
        title: "Failed to update project.",
      });
    }
  };

  const modifiedOnClose = () => {
    try {
      if (
        projectName !== viewingProject.projectName ||
        description !== viewingProject.description ||
        proAcronym !== viewingProject.proAcronym
      ) {
        updateProejctData();
      }
    } catch (error) {
      console.error(error);
    } finally {
      onClose();
    }
  };

  useEffect(() => {
    if (viewingProject?.id) {
      setProjectName(viewingProject.projectName);
      setDescription(viewingProject.description);
      setProAcronym(viewingProject.proAcronym);
    }
  }, [viewingProject]);

  return (
    <Modal isOpen={isOpen} onClose={modifiedOnClose} isCentered>
      <ModalOverlay />
      <ModalContent
        maxWidth={screenWidth < 550 ? "95%" : "500px"}
        bgColor={deleteClicked ? "#463a3a" : "white"}
        color={deleteClicked ? "white" : "black"}
      >
        <ModalHeader>
          {deleteClicked ? "Confirm Project Deletion" : "Update Project"}
        </ModalHeader>
        <ModalBody>
          {deleteClicked ? (
            <>
              <Heading size={"md"} marginTop={3}>
                Are you sure about deleting{" "}
                {viewingProject?.projectName?.toUpperCase() || "this project"}?
              </Heading>
              <Text marginTop={3}>This action is irreversible.</Text>
            </>
          ) : (
            <VStack gap={3}>
              <FormControl>
                <FormLabel>Project Name</FormLabel>
                <Input
                  value={projectName}
                  type="text"
                  onChange={(e) => setProjectName(e.target.value)}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Project Description</FormLabel>
                <Input
                  value={description}
                  type="text"
                  onChange={(e) => setDescription(e.target.value)}
                />
              </FormControl>
              <FormControl>
                <FormLabel>PM-Bot Name</FormLabel>
                <Input
                  value={proAcronym}
                  type="text"
                  onChange={(e) => {
                    if (e.target.value.length > 3) return;
                    setProAcronym(e.target.value?.toUpperCase());
                  }}
                />
                <FormHelperText>
                  Project Acronym should be 3 letters max.
                </FormHelperText>
              </FormControl>
            </VStack>
          )}
        </ModalBody>

        <ModalFooter>
          <Button
            mr={3}
            onClick={
              !deleteClicked
                ? () => setDeleteClicked(true)
                : () => setDeleteClicked(false)
            }
            color={!deleteClicked ? "grey" : "white"}
            colorScheme={deleteClicked && "blue"}
            leftIcon={!deleteClicked && <DeleteIcon />}
          >
            {!deleteClicked ? "Delete" : "Back"}
          </Button>
          <Button
            colorScheme={!deleteClicked && "blue"}
            color={deleteClicked ? "grey" : "white"}
            mr={3}
            onClick={!deleteClicked ? modifiedOnClose : deleteProject}
            leftIcon={deleteClicked && <DeleteIcon />}
          >
            {!deleteClicked
              ? projectName !== viewingProject.projectName ||
                description !== viewingProject.description
                ? "Save"
                : "Close"
              : "Confirm"}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default UpdateProjectModal;
