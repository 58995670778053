import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
  VStack,
  OrderedList,
  ListItem,
  UnorderedList,
  Code,
  useToast,
  Input,
  FormControl,
  FormLabel,
  Box,
  Flex,
  Link,
  Progress,
} from "@chakra-ui/react";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import { UserState } from "../../Context/UserProvider";

const SlackConnectModal = ({ isOpen, onClose }) => {
  const {
    setUpSlack,
    selectedOrganization: { id: orgId },
  } = UserState();
  const [slackApiKey, setSlackApiKey] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [step, setStep] = useState(0);
  const toast = useToast();

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      if (!slackApiKey) {
        toast({
          title: "Error",
          description: "Please enter a Slack API Key",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return;
      }

      const message = await setUpSlack(orgId, slackApiKey);
      toast({
        title: "Success",
        description: message,
        status: "success",
        duration: 5000,
        isClosable: true,
      });

      onClose();
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to set up Slack. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const steps = [
    {
      title: "Create a Slack App",
      content: (
        <VStack align="start" spacing={4}>
          <Text>Follow these steps to create a Slack App:</Text>
          <OrderedList spacing={2}>
            <ListItem>
              Go to the{" "}
              <Link href="https://api.slack.com/apps" isExternal>
                Slack API website <ExternalLinkIcon mx="2px" />
              </Link>
            </ListItem>
            <ListItem>Click on "Create New App"</ListItem>
            <ListItem>Choose "From scratch"</ListItem>
            <ListItem>Enter an App Name and select your workspace</ListItem>
            <ListItem>Click "Create App"</ListItem>
          </OrderedList>
        </VStack>
      ),
    },
    {
      title: "Set up Bot User",
      content: (
        <VStack align="start" spacing={4}>
          <Text>Now, set up the Bot User:</Text>
          <OrderedList spacing={2}>
            <ListItem>
              In the left sidebar, click on "OAuth & Permissions"
            </ListItem>
            <ListItem>Scroll to the "Scopes" section</ListItem>
            <ListItem>
              Under "Bot Token Scopes", click "Add an OAuth Scope"
            </ListItem>
            <ListItem>Add these scopes: chat:write, channels:read</ListItem>
          </OrderedList>
        </VStack>
      ),
    },
    {
      title: "Install the App",
      content: (
        <VStack align="start" spacing={4}>
          <Text>Install the app to your workspace:</Text>
          <OrderedList spacing={2}>
            <ListItem>
              Scroll to the top of the "OAuth & Permissions" page
            </ListItem>
            <ListItem>Click "Install to Workspace"</ListItem>
            <ListItem>Review the permissions and click "Allow"</ListItem>
          </OrderedList>
        </VStack>
      ),
    },
    {
      title: "Get the Bot Token",
      content: (
        <VStack align="start" spacing={4}>
          <Text>Retrieve your Bot Token:</Text>
          <OrderedList spacing={2}>
            <ListItem>
              After installation, you'll be on the "OAuth & Permissions" page
            </ListItem>
            <ListItem>
              Find the "Bot User OAuth Token" under "OAuth Tokens for Your
              Workspace"
            </ListItem>
            <ListItem>
              This token starts with <Code>xoxb-</Code>. This is your API key
              (Bot Token)
            </ListItem>
          </OrderedList>
        </VStack>
      ),
    },
    {
      title: "Connect to PMBot",
      content: (
        <VStack align="start" spacing={4}>
          <Text>Finally, connect Slack to PMBot:</Text>
          <FormControl>
            <FormLabel>Slack API Key (Bot Token)</FormLabel>
            <Input
              placeholder="Paste your xoxb- token here"
              value={slackApiKey}
              onChange={(e) => setSlackApiKey(e.target.value)}
            />
          </FormControl>
        </VStack>
      ),
    },
  ];

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={{ base: "full", md: "xl" }}
      isCentered
    >
      <ModalOverlay />
      <ModalContent
        maxHeight={{ base: "100vh", md: "80vh" }}
        display="flex"
        flexDirection="column"
      >
        <ModalHeader>Connect Slack to PMBot</ModalHeader>
        <ModalCloseButton />
        <ModalBody flex="1" overflow="hidden">
          <VStack align="stretch" spacing={4} height="100%">
            <Progress
              value={(step / (steps.length - 1)) * 100}
              size="sm"
              colorScheme="blue"
            />
            <Box flex="1" overflow="auto">
              <Text fontSize="xl" fontWeight="bold" mb={4}>
                {steps[step].title}
              </Text>
              <Box>{steps[step].content}</Box>
            </Box>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Flex width="100%" justify="space-between">
            <Button
              variant="ghost"
              onClick={() => setStep(Math.max(0, step - 1))}
              isDisabled={step === 0}
            >
              Previous
            </Button>
            <Button
              colorScheme="blue"
              onClick={() => {
                if (step === steps.length - 1) {
                  handleSubmit();
                } else {
                  setStep(Math.min(steps.length - 1, step + 1));
                }
              }}
              isLoading={isLoading}
            >
              {step === steps.length - 1 ? "Connect Slack" : "Next"}
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default SlackConnectModal;
