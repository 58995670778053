"use client";

import { Box, VStack, Divider } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Projects from "./Projects/Projects";
import Settings from "./Settings";
import Logout from "./Logout";
import { LayoutState } from "../../../Context/LayoutProvider";
import Organization from "../Organization/Organization";
import Status from "./Status";
import Dashboard from "./DashboardItem";
import Assistant from "./AssistantItem";

const SideBarContent = ({ onClose }) => {
  const { isPhone } = LayoutState();
  const [clicked, setClicked] = useState(false);

  useEffect(() => {
    if (clicked) {
      setClicked(false);
      onClose && onClose();
    }
  }, [clicked]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      height="100%"
      id="sidebarcontent-container"
    >
      <VStack
        spacing={3}
        align="stretch"
        flex={1}
        overflowY="auto"
        css={{
          "&::-webkit-scrollbar": {
            width: "4px",
          },
          "&::-webkit-scrollbar-track": {
            width: "6px",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "gray.300",
            borderRadius: "24px",
          },
        }}
      >
        {isPhone && <Organization />}
        <Dashboard setClicked={setClicked} />
        <Assistant setClicked={setClicked} />
        <Projects setClicked={setClicked} />
        {/* <Status /> */}
      </VStack>
      <Box mt={2}>
        <Divider mb={4} />
        <VStack spacing={3} align="stretch">
          <Settings />
          <Logout />
        </VStack>
      </Box>
    </Box>
  );
};

export default SideBarContent;
