import React, { useEffect, useState } from "react";
import { Box, Heading, Grid, GridItem } from "@chakra-ui/react";
import SettingsPanel from "../Components/SettingComponents/SettingsPanel";
import SettingExpanded from "../Components/SettingComponents/SettingExpanded";
import { LayoutState } from "../Context/LayoutProvider";
import { useOutletContext } from "react-router-dom";

const Settings = () => {
  const [selectedSetting, setSelectedSetting] = useState("");
  const { screenWidth, isPhone } = LayoutState();
  const { isSidebarOpen } = useOutletContext();

  useEffect(() => {
    !isPhone && setSelectedSetting("profile");
  }, [isPhone]);

  const sidebarWidth = 200; // Width of your sidebar
  const settingsPanelWidth = screenWidth > 580 ? 200 : 0; // Adjust this value as needed
  const marginLeft = isSidebarOpen ? `${sidebarWidth}px` : "0";
  return (
    <Box
      width={isSidebarOpen ? `calc(100vw - ${sidebarWidth}px)` : "100vw"}
      height="100vh"
      ml={marginLeft}
      transition="margin-left 0.3s ease, pointer-events 0.3s ease"
      overflow="hidden"
    >
      <Grid
        templateColumns={`${settingsPanelWidth}px 1fr`}
        height="calc(100vh - 80px)"
      >
        <GridItem>
          <Box
            position="fixed"
            width={`${settingsPanelWidth}px`}
            height="calc(100vh - 80px)"
            overflowY="auto"
          >
            <SettingsPanel
              setSelectedSetting={setSelectedSetting}
              selectedSetting={selectedSetting}
            />
          </Box>
        </GridItem>
        <GridItem overflowY="auto">
          <SettingExpanded
            setSelectedSetting={setSelectedSetting}
            selectedSetting={selectedSetting}
          />
        </GridItem>
      </Grid>
    </Box>
  );
};

export default Settings;
