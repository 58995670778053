import React, { useState } from "react";
import { UserState } from "../../Context/UserProvider";
import { LayoutState } from "../../Context/LayoutProvider";
import { AuthState } from "../../Context/AuthProvider";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Text,
  VStack,
  useToast,
  Flex,
  useColorModeValue,
  IconButton,
  Tooltip,
  HStack,
} from "@chakra-ui/react";
import { EmailIcon, ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { FaApple, FaFacebook, FaGoogle, FaMicrosoft } from "react-icons/fa";
import logo from "../../assets/logo-beta.png";
import { FcGoogle } from "react-icons/fc";

export default function Component({ setPage }) {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const { screenWidth } = LayoutState();
  const {
    reloadUser,
    preventUserFetch,
    allowUserFetch,
    createFirebaseUser,
    deleteFirebaseUser,
    saveUserData,
    handle3rdPartyAuth,
    checkUserExists,
  } = AuthState();
  const toast = useToast();
  const navigate = useNavigate();

  const bgColor = useColorModeValue("gray.50", "gray.900");
  const cardBgColor = useColorModeValue("white", "gray.800");
  const textColor = useColorModeValue("gray.800", "white");
  const placeholderColor = useColorModeValue("gray.400", "gray.500");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  const validate = () => {
    const errors = {};
    if (!formData.firstName) errors.firstName = "First Name is required";
    if (!formData.lastName) errors.lastName = "Last Name is required";
    if (!formData.email) errors.email = "Email is required";
    if (!formData.password) errors.password = "Password is required";
    if (!formData.confirmPassword)
      errors.confirmPassword = "Confirm Password is required";
    if (formData.password !== formData.confirmPassword)
      errors.confirmPassword = "Passwords do not match";

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (formData.email && !emailPattern.test(formData.email))
      errors.email = "Invalid email address";
    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    setLoading(true);
    try {
      preventUserFetch();
      const user = await createFirebaseUser(formData.email, formData.password);
      const userData = {
        firebaseUserId: user.uid,
        firstName: formData.firstName,
        lastName: formData.lastName,
        email: formData.email,
      };

      try {
        await saveUserData(userData);

        // navigate to create organization page.
        allowUserFetch();
        reloadUser();
        setLoading(false);
      } catch (error) {
        await deleteFirebaseUser(user);
        throw error;
      }
    } catch (error) {
      setLoading(false);
      if (error.code) {
        if (error.code === "auth/weak-password") {
          setErrors((prevErrors) => ({
            ...prevErrors,
            password: "Password is too weak",
          }));
          toast({
            title: "Error occurred",
            description:
              "Unfortunately we are facing some errors in signing up new users currently. Please try again in some time.",
            status: "error",
            duration: 9000,
            isClosable: true,
          });
        } else if (error.code === "auth/email-already-in-use") {
          setErrors((prevErrors) => ({
            ...prevErrors,
            email: "Email already in use",
          }));
          toast({
            description: "Email is already in use.",
            status: "error",
            duration: 9000,
            isClosable: true,
          });
        }
      }
    } finally {
      setLoading(false);
    }
  };

  const handle3rdPartySignUp = async (provider) => {
    try {
      preventUserFetch();
      const user = await handle3rdPartyAuth(provider);
      const userData = {
        firebaseUserId: user.uid,
        firstName: user.displayName?.split(" ")[0] || "",
        lastName: user.displayName?.split(" ").slice(1).join(" ") || "",
        email: user.email,
      };

      try {
        try {
          const response = await checkUserExists(
            user.email,
            user.uid,
            user.accessToken
          );
          console.log(response);

          if (response) {
            allowUserFetch();
            reloadUser();
            setLoading(false);
            return;
          }
        } catch (error) {
          console.log(`New User. Creating account.`);
        }

        await saveUserData(userData);

        // navigate to create organization page.
        navigate("/organizations");
        allowUserFetch();
        reloadUser();
        setLoading(false);
      } catch (error) {
        await deleteFirebaseUser(user);

        throw error;
      }
    } catch (error) {
      setLoading(false);
      if (error.code === "auth/email-already-in-use") {
        toast({
          description: "Email is already in use.",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Error occurred",
          description:
            "Unfortunately we are facing some errors in signing up new users currently. Please try again in some time.",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    }
  };

  return (
    <Flex
      width="100vw"
      align="center"
      justifyContent="center"
      height={"100vh"}
      // bg={bgColor}
      background={`radial-gradient(circle, rgba(255,255,255,0.2), rgba(255,255,255,0.2) 50%),
        linear-gradient(135deg,#0C9ECB, #96D0A2, #4D7CBE,#c1e1bf, #4853a4)`}
      paddingY={6}
      maxHeight={"100%"}
      overflow={"hidden"}
    >
      {/* <Box
        position="absolute"
        top={0}
        left={0}
        right={0}
        height="50%"
        // bgImage={`url(${signup})`}
        // bgPosition="center"
        // bgRepeat="no-repeat"
        // bgSize="cover"

        filter="blur(8px)"
        opacity={0.4}
      /> */}
      <Box
        bg={cardBgColor}
        // bg="rgba(255, 255, 255, 0.8)"
        p={8}
        py={3}
        rounded="xl"
        shadow="2xl"
        width={{ base: "90%", sm: "400px" }}
        zIndex={1}
        position="relative"
        maxH={"100%"}
        overflow={"scroll"}
      >
        <VStack spacing={8} align="stretch">
          <Box textAlign="center">
            <Image
              src={logo}
              alt="Logo"
              height="100px"
              mx="auto"
              mb={4}
              onClick={() => navigate("/")}
              _hover={{ cursor: "pointer" }}
            />
            <Heading color="blue.400" fontSize="3xl" fontWeight="bold">
              Create Account
            </Heading>
            <Text mt={2} color={textColor}>
              Already have an account?{" "}
              <Text
                as="span"
                color="blue.500"
                fontWeight="semibold"
                cursor="pointer"
                _hover={{ textDecoration: "underline" }}
                onClick={() => setPage("signin")}
              >
                Sign in
              </Text>
            </Text>
          </Box>
          <VStack as="form" spacing={3}>
            <FormControl isRequired isInvalid={errors.firstName}>
              <FormLabel htmlFor="firstName" color={textColor}>
                First Name
              </FormLabel>
              <Input
                id="firstName"
                name="firstName"
                type="text"
                variant="filled"
                bg="gray.100"
                border="none"
                _hover={{ bg: "gray.200" }}
                _focus={{ bg: "gray.200", borderColor: "blue.300" }}
                onChange={handleChange}
                value={formData.firstName}
                placeholder="John"
                _placeholder={{ color: placeholderColor }}
              />
              <FormErrorMessage>{errors.firstName}</FormErrorMessage>
            </FormControl>
            <FormControl isRequired isInvalid={errors.lastName}>
              <FormLabel htmlFor="lastName" color={textColor}>
                Last Name
              </FormLabel>
              <Input
                id="lastName"
                name="lastName"
                type="text"
                variant="filled"
                bg="gray.100"
                border="none"
                _hover={{ bg: "gray.200" }}
                _focus={{ bg: "gray.200", borderColor: "blue.300" }}
                onChange={handleChange}
                value={formData.lastName}
                placeholder="Doe"
                _placeholder={{ color: placeholderColor }}
              />
              <FormErrorMessage>{errors.lastName}</FormErrorMessage>
            </FormControl>
            <FormControl isRequired isInvalid={errors.email}>
              <FormLabel htmlFor="email" color={textColor}>
                Email
              </FormLabel>
              <InputGroup>
                <InputLeftElement pointerEvents="none">
                  <EmailIcon color="gray.400" />
                </InputLeftElement>
                <Input
                  id="email"
                  name="email"
                  type="email"
                  variant="filled"
                  bg="gray.100"
                  border="none"
                  _hover={{ bg: "gray.200" }}
                  _focus={{ bg: "gray.200", borderColor: "blue.300" }}
                  onChange={handleChange}
                  value={formData.email}
                  placeholder="john.doe@example.com"
                  _placeholder={{ color: placeholderColor }}
                />
              </InputGroup>
              <FormErrorMessage>{errors.email}</FormErrorMessage>
            </FormControl>
            <FormControl isRequired isInvalid={errors.password}>
              <FormLabel htmlFor="password" color={textColor}>
                Password
              </FormLabel>
              <InputGroup>
                <Input
                  id="password"
                  name="password"
                  type={showPassword ? "text" : "password"}
                  variant="filled"
                  bg="gray.100"
                  border="none"
                  _hover={{ bg: "gray.200" }}
                  _focus={{ bg: "gray.200", borderColor: "blue.300" }}
                  onChange={handleChange}
                  value={formData.password}
                  placeholder="********"
                  _placeholder={{ color: placeholderColor }}
                />
                <InputRightElement>
                  <IconButton
                    aria-label={
                      showPassword ? "Hide password" : "Show password"
                    }
                    icon={showPassword ? <ViewOffIcon /> : <ViewIcon />}
                    variant="ghost"
                    onClick={() => setShowPassword((show) => !show)}
                  />
                </InputRightElement>
              </InputGroup>
              <FormErrorMessage>{errors.password}</FormErrorMessage>
            </FormControl>
            <FormControl isRequired isInvalid={errors.confirmPassword}>
              <FormLabel htmlFor="confirmPassword" color={textColor}>
                Confirm Password
              </FormLabel>
              <InputGroup>
                <Input
                  id="confirmPassword"
                  name="confirmPassword"
                  type={showConfirmPassword ? "text" : "password"}
                  variant="filled"
                  bg="gray.100"
                  border="none"
                  _hover={{ bg: "gray.200" }}
                  _focus={{ bg: "gray.200", borderColor: "blue.300" }}
                  onChange={handleChange}
                  value={formData.confirmPassword}
                  placeholder="********"
                  _placeholder={{ color: placeholderColor }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") handleSubmit(e);
                  }}
                />
                <InputRightElement>
                  <IconButton
                    aria-label={
                      showConfirmPassword ? "Hide password" : "Show password"
                    }
                    icon={showConfirmPassword ? <ViewOffIcon /> : <ViewIcon />}
                    variant="ghost"
                    onClick={() => setShowConfirmPassword((show) => !show)}
                  />
                </InputRightElement>
              </InputGroup>
              <FormErrorMessage>{errors.confirmPassword}</FormErrorMessage>
            </FormControl>
            <Button
              type="submit"
              colorScheme="blue"
              size="lg"
              fontSize="md"
              isLoading={loading}
              onClick={handleSubmit}
              width="full"
              _hover={{ transform: "translateY(-2px)", boxShadow: "lg" }}
              transition="all 0.2s"
            >
              Sign up
            </Button>
          </VStack>
          <Text fontSize="sm" color={textColor} textAlign="center">
            Or continue with
          </Text>
          <HStack spacing={4} justify="center">
            <Tooltip label="Sign In with Google">
              <Button
                variant="outline"
                borderRadius="full"
                size="lg"
                bgColor={"white"}
                onClick={() => handle3rdPartySignUp("google")}
              >
                <FcGoogle />
              </Button>
            </Tooltip>
            <Tooltip label="Sign In with Microsoft">
              <Button
                variant="outline"
                borderRadius="full"
                size="lg"
                bgColor={"white"}
                onClick={() => handle3rdPartySignUp("microsoft")}
              >
                <FaMicrosoft />
              </Button>
            </Tooltip>
          </HStack>
        </VStack>
      </Box>
    </Flex>
  );
}
