import React from "react";
import { Flex, Text, Icon, Button, Avatar } from "@chakra-ui/react";
import { RefreshCw } from "lucide-react";

export default function ChatbotHeader({
  pmbotName,
  refreshChat,
  organization,
}) {
  return (
    <Flex
      align="center"
      justify="space-between"
      p={4}
      borderBottom="1px solid"
      borderColor="gray.200"
      bg="white"
      position="sticky"
      top={0}
      // zIndex={10}
      ml={"5px"}
      backdropFilter="blur(10px)"
      backgroundColor="rgba(255, 255, 255, 0.9)"
      boxShadow={"2px 1px 2px rgba(0, 0, 0, 0.1)"}
    >
      <Flex align="center" gap={3}>
        <Avatar name={pmbotName} src={organization?.logoUrl} size="sm" />
        <Text fontSize="lg" fontWeight="semibold">
          {pmbotName}
        </Text>
      </Flex>
      <Button
        variant="ghost"
        size="sm"
        leftIcon={<RefreshCw size={16} />}
        onClick={refreshChat}
        color="gray.600"
        _hover={{ bg: "gray.100" }}
      >
        Reset Chat
      </Button>
    </Flex>
  );
}
