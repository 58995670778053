import React, { useState, useEffect, useRef } from "react";
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  Button,
  VStack,
  Text,
  Box,
  Spinner,
} from "@chakra-ui/react";
import { format } from "date-fns";
import { ServiceState } from "../../Context/ServiceProvider";

const NotificationsPopOver = ({ children }) => {
  const { notifications, isLoading, hasMore, handleLoadMore } = ServiceState();

  return (
    <Popover>
      <PopoverTrigger>{children}</PopoverTrigger>
      <PopoverContent bg={"gray.50"} boxShadow={"lg"} mr={2}>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverHeader>Notifications</PopoverHeader>
        <PopoverBody maxHeight="300px" overflowY="auto">
          {notifications && notifications.length > 0 ? (
            <VStack spacing={2} align="stretch">
              {notifications.map((notification, index) => (
                <Box
                  key={index}
                  p={2}
                  borderWidth={1}
                  borderRadius="md"
                  bgColor={"white"}
                  _hover={{
                    cursor: "pointer",
                  }}
                >
                  <Text fontSize="sm">{notification.message}</Text>
                  <Text fontSize="xs" color="gray.500">
                    {format(
                      new Date(notification.timestamp),
                      "MMM d, yyyy HH:mm"
                    )}
                  </Text>
                </Box>
              ))}
              {isLoading && <Spinner />}
            </VStack>
          ) : (
            <Box
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              height={"80px"}
            >
              No notifications
            </Box>
          )}
        </PopoverBody>
        <PopoverFooter>
          {hasMore && !(notifications?.length % 10) && (
            <Button onClick={handleLoadMore} isLoading={isLoading} width="100%">
              Load More
            </Button>
          )}
        </PopoverFooter>
      </PopoverContent>
    </Popover>
  );
};

export default NotificationsPopOver;
