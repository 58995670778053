import React, { useEffect, useRef, useState } from "react";
import MarkdownRenderer from "./MarkdownRenderer";
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  Heading,
  HStack,
  Text,
  VStack,
} from "@chakra-ui/react";
import ProjectDropDown from "../Miscellaneous/Dropdowns/ProjectDropDown";
import { findProjectName } from "../Miscellaneous/MyTasks/utils";
import { UserState } from "../../Context/UserProvider";
import PerfectScrollbar from "react-perfect-scrollbar";
// import MessageLoading from "./MessageLoadingAnimation";

const AssistantMessage = ({
  message,
  conversation,
  setConversation,
  context,
  setContext,
}) => {
  const { projects } = UserState();
  const [requiredFields, setRequiredFields] = useState([]);
  const [currFieldIdx, setCurrFieldIdx] = useState(0);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [fields, setFields] = useState({});
  const [errors, setErrors] = useState({});
  const scrollRef = useRef(null);

  const handleSubmitContext = () => {
    const lastMessage = conversation[conversation.length - 1];
    const projectName = findProjectName(fields.projectId, projects);
    setContext((prevContext) => ({
      ...prevContext,
      projectId: fields.projectId,
      projectName: projectName,
    }));
    setConversation((prevConversation) =>
      lastMessage && lastMessage.type === "assistant"
        ? [
            ...prevConversation.slice(0, prevConversation.length - 1),
            {
              type: "assistant",
              text: `Selected project: ${projectName}`,
            },
          ]
        : [
            ...prevConversation,
            {
              type: "user",
              text: `Selected project: ${projectName}`,
            },
          ]
    );
    setIsSubmitted(true);
  };

  const updateFieldData = (value) => {
    if (!value) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [requiredFields[currFieldIdx]]: "Need to fill this field.",
      }));
      return;
    }

    if (value !== fields[requiredFields[currFieldIdx]]) {
      setFields((prevFields) => ({
        ...prevFields,
        [requiredFields[currFieldIdx]]: value,
      }));
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      [requiredFields[currFieldIdx]]: "",
    }));
  };

  useEffect(() => {
    setRequiredFields(message?.inputs);
  }, [isSubmitted]);

  useEffect(() => {
    if (requiredFields?.length > 0) {
      setRequiredFields([]);
    }
  }, [conversation]);

  useEffect(() => {
    const handleWheel = (e) => {
      if (scrollRef.current) {
        e.preventDefault();
        scrollRef.current.scrollLeft += e.deltaY;
      }
    };

    const scrollContainer = scrollRef.current;
    if (scrollContainer) {
      scrollContainer.addEventListener("wheel", handleWheel, {
        passive: false,
      });
    }

    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener("wheel", handleWheel);
      }
    };
  }, []);

  return requiredFields?.length > 0 ? (
    <>
      <Box display={"flex"} flexDir={"column"} gap={1}>
        <Heading size={"sm"}>{message.headingText}</Heading>
        {message.text && <MarkdownRenderer content={message.text} />}
        <FormControl isInvalid={errors[requiredFields[currFieldIdx]]}>
          {requiredFields[currFieldIdx] === "projectId" && (
            <PerfectScrollbar
              options={{
                suppressScrollY: true,
                useBothWheelAxes: false,
              }}
              style={{
                width: "100%",
                height: `calc(100% + 14px)`,
                paddingRight: "0px",
              }}
            >
              <HStack
                display={"flex"}
                justifyContent={"flex-start"}
                gap={2}
                overflow={"scroll"}
              >
                {projects.map((project) => (
                  <Button
                    key={project.id}
                    onClick={() => updateFieldData(project.id)}
                    overflow={"hidden"}
                    whiteSpace={"nowrap"}
                    bg={"white"}
                    // width={"100%"}
                    color={"gray.800"}
                    _hover={{
                      bg: "blue.100",
                      transform: "scale(1.05)",
                    }}
                    transition="all 0.2s"
                    fontWeight="medium"
                    // px={1}
                    textAlign={"left"}
                    boxShadow="sm"
                  >
                    {project.projectName}
                  </Button>
                ))}
              </HStack>
            </PerfectScrollbar>
          )}

          {errors[requiredFields[currFieldIdx]] && (
            <FormErrorMessage>
              {errors[requiredFields[currFieldIdx]]}
            </FormErrorMessage>
          )}
          {/* <Box display={"flex"} justifyContent={"space-between"} mt={2}>
        {currFieldIdx !== 0 ? (
          <Button onClick={() => updateCurrentIdx(-1)} size={"sm"}>
            Prev
          </Button>
        ) : (
          <Button colorScheme="red" size={"sm"} onClick={onCancel}>
            Cancel
          </Button>
        )}
        {currFieldIdx === inputsNeeded.length - 1 ? (
          <Button
            size={"sm"}
            onClick={() => handleSubmitQuery(message.text, fields)}
            isLoading={loading}
            colorScheme="green"
          >
            Generate
          </Button>
        ) : (
          <Button size={"sm"} onClick={() => updateCurrentIdx(1)}>
            Next
          </Button>
        )}
      </Box> */}
          {/* <Button
          size={"sm"}
          width={"full"}
          mt={2}
          colorScheme="blue"
          onClick={handleSubmitContext}
        >
          Submit
        </Button> */}
        </FormControl>
      </Box>
    </>
  ) : (
    <MarkdownRenderer content={message.text || message.onIgnoredText} />
  );
};

export default AssistantMessage;
