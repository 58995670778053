import React, { useState, useEffect } from "react";
import {
  Box,
  Avatar,
  Heading,
  Text,
  HStack,
  VStack,
  Divider,
  Tag,
  Flex,
  Badge,
  Stat,
  StatLabel,
  StatNumber,
  StatGroup,
  Button,
  Spinner,
  Center,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

const MyProfile = () => {
  const [userData, setUserData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate()
  useEffect(() => {
    setUserData({
      currentBandwidth: 27.0,
      currentTaskHours: 39.5,
      currentTasks: 42.0,
      email: "saeed.ahmed@lumiorc.com",
      firstName: "Saeed",
      lastName: "Ahmed",
      imageUrl:
        "https://storage.googleapis.com/lumiorc-pmbot-images/users/images/66d80419cf871d807e5a2ec3/a-guy-sitting-on-his-desk-wearing-a-black-hoodie-working-on-his-laptop.png",
      weeklyHours: 40.0,
      tasksCompleted: 61,
      tasksFailed: 0,
      tasksSuccessful: 0,
      totalTasks: 31,
      skills: { html: "3" },
    });
  }, []);

  if (isLoading) {
    return (
      <Center height="100vh" width="100vw">
        <Spinner size="xl" />
      </Center>
    );
  }

  if (!userData) {
    return (
      <Center height="100vh">
        <Text color="red.500">Failed to load user data. Please try again.</Text>
      </Center>
    );
  }

  const {
    firstName,
    lastName,
    email,
    imageUrl,
    weeklyHours,
    currentBandwidth,
    currentTaskHours,
    tasksCompleted,
    tasksFailed,
    tasksSuccessful,
    totalTasks,
    skills,
  } = userData;

  return (
    <Box
      w="100vw"
      h="100vh"
      bg="gray.50"
      p={6}
      overflow="auto"
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      <Box
        w={{ base: "100%", md: "80%", lg: "60%" }}
        bg="white"
        borderRadius="md"
        shadow="md"
        p={6}
        minH="80vh"
        display="flex"
        flexDirection="column"
        overflow={"auto"}
        justifyContent="space-between"
      >
        {/* Header Section */}
        <Flex
          direction={{ base: "column", md: "row" }}
          align={{ base: "center", md: "flex-start" }}
          gap={6}
          mb={6}
        >
          <Avatar size="2xl" src={imageUrl} name={`${firstName} ${lastName}`} />
          <VStack align="flex-start" spacing={2}>
            <Heading size="lg">{`${firstName} ${lastName}`}</Heading>
            <Text fontSize="md" color="gray.600">
              {email}
            </Text>
            <HStack>
              <Badge colorScheme="green">{`Bandwidth: ${currentBandwidth}`}</Badge>
              <Badge colorScheme="blue">{`Weekly Hours: ${weeklyHours}`}</Badge>
            </HStack>
          </VStack>
        </Flex>

        <Divider />

        {/* Stats Section */}
        <VStack align="stretch" spacing={6} mt={6}>
          <StatGroup flexWrap="wrap" gap={4}>
            <Stat minW="150px">
              <StatLabel>Total Tasks</StatLabel>
              <StatNumber>{totalTasks}</StatNumber>
            </Stat>
            <Stat minW="150px">
              <StatLabel>Tasks Completed</StatLabel>
              <StatNumber>{tasksCompleted}</StatNumber>
            </Stat>
            <Stat minW="150px">
              <StatLabel>Tasks Failed</StatLabel>
              <StatNumber>{tasksFailed}</StatNumber>
            </Stat>
            <Stat minW="150px">
              <StatLabel>Successful Tasks</StatLabel>
              <StatNumber>{tasksSuccessful}</StatNumber>
            </Stat>
          </StatGroup>

          <Divider />

          {/* Skills Section */}
          <Box>
            <Heading size="md" mb={2}>
              Skills
            </Heading>
            <HStack wrap="wrap" spacing={4}>
              {Object.entries(skills).map(([skill, level]) => (
                <Tag key={skill} colorScheme="teal">{`${skill.toUpperCase()} (Level: ${level})`}</Tag>
              ))}
            </HStack>
          </Box>

          {/* Metrics Section */}
          <Box>
            <Heading size="md" mb={2}>
              Metrics
            </Heading>
            <HStack wrap="wrap" spacing={4}>
              <Badge colorScheme="purple">{`Task Hours: ${currentTaskHours}`}</Badge>
            </HStack>
          </Box>
        </VStack>
      </Box>

      {/* Back Button */}
      <Button
        mt={6}
        colorScheme="blue"
        onClick={() => navigate('/dashboard')}
      >
        Back to Organization
      </Button>
    </Box>
  );
};

export default MyProfile;
