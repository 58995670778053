import React, { useEffect } from "react";
import { Grid, GridItem, Box } from "@chakra-ui/react";
import Header from "../DashboardComponents/Header";
import SideBar from "../DashboardComponents/SideBar";
import { Outlet } from "react-router-dom";
import { useDisclosure } from "@chakra-ui/react";
import { LayoutState } from "../../Context/LayoutProvider";
import { UserState } from "../../Context/UserProvider";

const Layout = () => {
  const { organizations } = UserState();
  const { isPhone } = LayoutState();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const sidebarWidth = 100; // Width of your sidebar

  useEffect(() => {
    !isPhone ? onOpen() : onClose();
  }, [isPhone]);

  useEffect(() => {
    if (!(organizations && organizations?.length > 0)) {
      onClose();
    }
  }, [organizations]);

  return (
    <Box height="100vh" width="100vw">
      <Header isOpen={isOpen} onClose={onClose} onOpen={onOpen} />

      <Grid templateColumns={`1fr`} height="calc(100vh - 60px)">
        <GridItem>
          <Box
            position="fixed"
            width={`${sidebarWidth}px`}
            height="calc(100vh - 60px)"
            overflowY="auto"
          >
            <SideBar onOpen={onOpen} isOpen={isOpen} onClose={onClose} />
          </Box>
        </GridItem>
        {/* Main Content Area */}
        {!(isOpen && isPhone) && (
          <GridItem as="main" overflow={"auto"}>
            <Outlet context={{ isSidebarOpen: isOpen }} />
          </GridItem>
        )}
      </Grid>
    </Box>
  );
};

export default Layout;
