import React, { useEffect, useRef, useState } from "react";
import { Box, useDisclosure } from "@chakra-ui/react";
import Main from "../Components/DashboardComponents/Main";
import { UserState } from "../Context/UserProvider";
import { AuthState } from "../Context/AuthProvider";
import { useOutletContext } from "react-router-dom";

const Dashboard = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { organizations, selectedOrganization, organizationMembers } =
    UserState();
  const { user, reloadUser } = AuthState();
  const myTasksRef = useRef(null);
  const myReportsRef = useRef(null);
  const metricsRef = useRef(null);
  const reloadUserCountRef = useRef(0);

  useEffect(() => {
    if (
      organizations &&
      user?.orgs &&
      organizations.length !== Object.keys(user.orgs).length
    ) {
      console.log("this");
      if (reloadUserCountRef.current < 1) {
        reloadUser();
        reloadUserCountRef.current++;
        setTimeout(() => {
          reloadUserCountRef.current = 0;
        }, 3000);
      }
    }
  }, [organizations]);

  return (
    <Box
      style={{ all: "unset" }}
      position={"fixed"}
      top={0}
      left={0}
      width={"100vw"}
    >
      {organizations && selectedOrganization && organizationMembers && (
        <Box
          display="flex"
          maxWidth={"100vw"}
          minHeight={"100vh"}
          width={"100vw"}
          flexDirection={"column"}
        >
          <Box
            className="post-header"
            maxHeight={`100vh`}
            overflow={"none"}
            flex={1}
            margin={0}
            padding={0}
            display={"flex"}
            flexDirection={"row"}
          >
            <Main
              isOpen={isOpen}
              onClose={onClose}
              myReportsRef={myReportsRef}
              myTasksRef={myTasksRef}
              metricsRef={metricsRef}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default Dashboard;
