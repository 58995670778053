import { Box, IconButton, useDisclosure } from "@chakra-ui/react";
import React from "react";
import { IoMdNotificationsOutline } from "react-icons/io";
import NotificationsPopOver from "./NotificationsPopOver";
import { ServiceState } from "../../Context/ServiceProvider";

const Notifications = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { hasUnreadNotifications, setHasUnreadNotifications } = ServiceState();

  return (
    <NotificationsPopOver isOpen={isOpen} onClose={onClose}>
      <Box position="relative">
        <IconButton
          variant="ghost"
          icon={<IoMdNotificationsOutline size="1.5em" />}
          aria-label="Notifications"
          onClick={() => {
            onOpen();
            setHasUnreadNotifications(false);
          }}
        />
        {hasUnreadNotifications && (
          <Box
            bg="red.500"
            width="8px"
            height="8px"
            borderRadius="full"
            position="absolute"
            top="2px"
            right="2px"
          />
        )}
      </Box>
    </NotificationsPopOver>
  );
};

export default Notifications;
