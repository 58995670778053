import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";
import UserProvider from "./Context/UserProvider";
import LayoutProvider from "./Context/LayoutProvider";
import AuthProvider from "./Context/AuthProvider";
import ServiceProvider from "./Context/ServiceProvider";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ChakraProvider>
    <LayoutProvider>
      <BrowserRouter>
        <AuthProvider>
          <UserProvider>
            <ServiceProvider>
              <App />
            </ServiceProvider>
          </UserProvider>
        </AuthProvider>
      </BrowserRouter>
    </LayoutProvider>
  </ChakraProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(// console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
