import React, { useRef, useEffect, useState } from "react";
import {
  VStack,
  Text,
  Box,
  IconButton,
  useToast,
  Avatar,
  Image,
  Flex,
  Button,
} from "@chakra-ui/react";
import { CopyIcon } from "@chakra-ui/icons";
import { UserState } from "../../Context/UserProvider";
import { LayoutState } from "../../Context/LayoutProvider";
import UserMessage from "./UserMessage";
import AssistantMessage from "./AssistantMessage";

export default function ChatbotInterface({
  conversation,
  setConversation,
  clickedQuery,
  setClickedQuery,
  loading,
  setLoading,
  context,
  setContext,
}) {
  const { user, selectedOrganization } = UserState();
  const { isPhone } = LayoutState();
  const [action, setAction] = useState(null);
  const messagesEndRef = useRef(null);
  const toast = useToast();

  const actions = [
    {
      name: "select_project",
      description: "Select a project to store as context.",
    },
    {
      name: "select_assignee",
      description: "Select an assignee to store as context.",
    },
  ];

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(scrollToBottom, [conversation]);

  const cancelQueryClicked = () => {
    const lastConversation = conversation[conversation.length - 1];
    setConversation((prevConversation) =>
      lastConversation && lastConversation.type === "user"
        ? [...prevConversation.slice(0, prevConversation.length - 1)]
        : [...prevConversation]
    );
    setClickedQuery(null);
  };

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text).then(
      () => {
        toast({
          title: "Copied to clipboard",
          status: "success",
          duration: 2000,
          isClosable: true,
        });
      },
      (err) => {
        console.error("Could not copy text: ", err);
        toast({
          title: "Failed to copy",
          status: "error",
          duration: 2000,
          isClosable: true,
        });
      }
    );
  };

  useEffect(() => {
    if (conversation?.length === 0) {
      setConversation((prevConversation) => [
        ...(prevConversation || []),
        {
          type: "assistant",
          text: user?.firstName
            ? `Hello ${user?.firstName}. Which project may I assist you with?`
            : `Welcome to PMBot.`,
          headingText: "Select a Project.",
          onIgnoredText: "No Project Selected.",
          inputs: ["projectId"],
        },
        // {
        //   type: "assistant",
        //   headingText: "Select a Project.",
        //   onIgnoredText: "No Project Selected.",
        //   inputs: ["projectId"],
        // },
      ]);
    }
  }, [conversation]);

  return (
    <VStack spacing={6} flex={1} overflowY="auto" width="100%" px={4}>
      {conversation?.map((message, index) => (
        <Flex
          key={index}
          w="full"
          justify={message.type === "user" ? "flex-end" : "flex-start"}
        >
          <Flex
            maxW={isPhone ? "90%" : "70%"}
            gap={2}
            align="flex-start"
            direction={message.type === "user" ? "row-reverse" : "row"}
          >
            <Avatar
              name={
                message.type === "user"
                  ? `${user?.firstName} ${user?.lastName}`
                  : selectedOrganization.pmbotName
              }
              src={
                message.type === "user"
                  ? user?.imageUrl
                  : selectedOrganization?.logoUrl
              }
              size="sm"
            />
            <Box position="relative" width="full">
              <Box
                bg={message.type === "user" ? "blue.500" : "gray.100"}
                color={message.type === "user" ? "white" : "gray.800"}
                p={4}
                py={3}
                borderRadius="2xl"
                borderTopLeftRadius={message.type === "user" ? "2xl" : "sm"}
                borderTopRightRadius={message.type === "user" ? "sm" : "2xl"}
                position="relative"
                _hover={{ "& > .message-actions": { opacity: 1 } }}
              >
                {message.type === "user" ? (
                  <UserMessage
                    conversation={conversation}
                    setConversation={setConversation}
                    message={message}
                    clickedQuery={clickedQuery}
                    setClickedQuery={setClickedQuery}
                    loading={loading}
                    setLoading={setLoading}
                    context={context}
                    onCancel={cancelQueryClicked}
                    action={action}
                  />
                ) : (
                  <Box fontSize="sm">
                    <AssistantMessage
                      conversation={conversation}
                      setConversation={setConversation}
                      message={message}
                      context={context}
                      setContext={setContext}
                    />
                    {message.actions && (
                      <Flex gap={2} mt={4} flexWrap="wrap">
                        {message.actions.map((action, idx) => (
                          <Button
                            key={idx}
                            size="sm"
                            variant={action.primary ? "solid" : "outline"}
                            colorScheme="blue"
                            onClick={action.onClick}
                            leftIcon={action.icon}
                          >
                            {action.label}
                          </Button>
                        ))}
                      </Flex>
                    )}
                  </Box>
                )}
                <Box
                  className="message-actions"
                  position="absolute"
                  top={2}
                  right={2}
                  opacity={0}
                  transition="opacity 0.2s"
                  bgColor={"inherit"}
                >
                  {message.type === "assistant" && (
                    <IconButton
                      aria-label="Copy message"
                      icon={<CopyIcon />}
                      size="xs"
                      bgColor={"inherit"}
                      variant="ghost"
                      onClick={() => handleCopy(message.text)}
                    />
                  )}
                </Box>
              </Box>
              <Text
                fontSize="xs"
                color="gray.500"
                mt={1}
                textAlign={message.type === "user" ? "right" : "left"}
              >
                {new Date().toLocaleTimeString([], {
                  hour: "2-digit",
                  minute: "2-digit",
                })}
              </Text>
            </Box>
          </Flex>
        </Flex>
      ))}
      <div ref={messagesEndRef} />
    </VStack>
  );
}
