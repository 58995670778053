import { Box, VStack, Input, Flex } from "@chakra-ui/react";
import React, { useState, useEffect, useRef } from "react";
import { LayoutState } from "../../Context/LayoutProvider";
import { UserState } from "../../Context/UserProvider";
import ChatbotInterface from "./ChatbotInterface";
import QueryList from "./QueryList";
import ChatbotHeader from "./ChatbotHeader";
import useAssistant from "../../hooks/useAssistant";
import { useOutletContext } from "react-router-dom";

const Main = ({ isOpen, onClose }) => {
  const { selectedOrganization } = UserState();
  const {
    meetingPoints,
    generateProjectReport,
    userProgress,
    bestContributor,
    summarizeCurrentSprint,
    projectHealth,
    teamBandwidth,
    riskBlockers,
    teamBurnrate,
  } = useAssistant(selectedOrganization?.id);
  const { isPhone } = LayoutState();
  const { isSidebarOpen } = useOutletContext();
  const [spacingFromTop, setSpacingFromTop] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [clickedQuery, setClickedQuery] = useState(null);
  const [conversation, setConversation] = useState([]);
  const [pmbotName, setPmbotName] = useState("Assistant");
  const [context, setContext] = useState({}); // {projectId: 1, assigneeId: 1, ...} -> this will be context for the chatbot and if it is present, we will not ask for user input
  const [loading, setLoading] = useState(false);
  const inputRef = useRef(null);

  const queries = [
    {
      id: 1,
      value: "Meeting Agenda",
      func: meetingPoints,
      requiredFields: ["projectId"],
      type: "user",
    },
    {
      id: 2,
      value: "Project Report",
      func: generateProjectReport,
      requiredFields: ["projectId"],
      type: "user",
    },
    {
      id: 3,
      value: "User's Progress",
      func: userProgress,
      requiredFields: ["projectId", "assigneeId"],
      type: "user",
    },
    {
      id: 4,
      value: "Best Contributor",
      func: bestContributor,
      requiredFields: ["projectId"],
      type: "user",
    },
    {
      id: 5,
      value: "Sprint's Summary",
      func: summarizeCurrentSprint,
      requiredFields: ["projectId"],
      type: "user",
    },
    {
      id: 6,
      value: "Project Health",
      func: projectHealth,
      requiredFields: ["projectId"],
      type: "user",
    },
    {
      id: 7,
      value: "Team Bandwidth",
      func: teamBandwidth,
      requiredFields: ["projectId"],
      type: "user",
    },
    {
      id: 8,
      value: "Risk & Blockers",
      func: riskBlockers,
      requiredFields: ["projectId"],
      type: "user",
    },
    {
      id: 9,
      value: "Team Burnrate",
      func: teamBurnrate,
      requiredFields: ["projectId"],
      type: "user",
    },
  ];

  const refreshChat = () => {
    setConversation([]);
    setContext({});
  };

  const handleQueryClicked = (query) => {
    const lastConversation = conversation[conversation.length - 1];
    setConversation((prevConversation) =>
      lastConversation && lastConversation.type === "user"
        ? [
            ...prevConversation.slice(0, prevConversation.length - 1),
            { type: "user", text: query.value },
          ]
        : [...prevConversation, { type: "user", text: query.value }]
    );
    setClickedQuery(query);
    setSearchQuery("");
  };

  const filteredQueries = queries.filter((query) =>
    query.value.toLowerCase().includes(searchQuery.toLowerCase())
  );

  useEffect(() => {
    if (selectedOrganization) {
      setPmbotName(selectedOrganization.pmbotName);
    }
    setConversation([]);
    inputRef.current.focus();
  }, [selectedOrganization]);

  useEffect(() => {
    const headerContainer =
      document.getElementsByClassName("header-container")[0];
    setSpacingFromTop(headerContainer.clientHeight);
  }, []);

  return (
    <Box
      flex={1}
      marginRight={0}
      marginLeft={isSidebarOpen ? `200px` : "0px"}
      pointerEvents={isOpen ? "none" : "auto"}
      transition="margin-left 0.3s ease, pointer-events 0.3s ease"
      display={"flex"}
      flexDir={"column"}
      height={`calc(100vh - ${spacingFromTop}px)`}
      maxHeight={`calc(100vh - ${spacingFromTop}px)`}
      overflow="hidden"
    >
      <Box flex={1} display="flex" flexDirection="column" overflow={"hidden"}>
        {isPhone && isOpen && (
          <Box
            position="absolute"
            top="0"
            left="0"
            width="100%"
            height="100%"
            zIndex="1"
            bg="transparent"
            pointerEvents="auto"
            bgColor={"#0000002b"}
            onClick={onClose}
          />
        )}

        <Flex direction="column" flex={1} overflow={"hidden"}>
          <ChatbotHeader pmbotName={pmbotName} refreshChat={refreshChat} />
          <Box
            flex={1}
            overflow="hidden"
            p={4}
            display="flex"
            flexDir={"column"}
          >
            <ChatbotInterface
              conversation={conversation}
              setConversation={setConversation}
              clickedQuery={clickedQuery}
              setClickedQuery={setClickedQuery}
              loading={loading}
              setLoading={setLoading}
              context={context}
              setContext={setContext}
            />
          </Box>
          <Box p={4} borderTop="1px solid" borderColor="gray.200">
            <VStack spacing={4} align="stretch">
              {!loading && (
                <QueryList
                  queries={filteredQueries}
                  onQueryClick={handleQueryClicked}
                />
              )}
              <Input
                placeholder="Search for a query..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                ref={inputRef}
              />
            </VStack>
          </Box>
        </Flex>
      </Box>
    </Box>
  );
};

export default Main;
