import React, { useState, useEffect } from "react";
import { Box, Heading } from "@chakra-ui/react";
import { SettingsIcon } from "@chakra-ui/icons";
import Navbar from "./Navbar";
import Board from "./Board";
import Backlogs from "./Backlogs";
import Overview from "./ProjectSettings/Settings";
import Milestones from "./Milestones/Milestones";
import { LayoutState } from "../../Context/LayoutProvider";
import { UserState } from "../../Context/UserProvider";
import { useOutletContext } from "react-router-dom";

const Main = ({ isOpen, onClose, viewingProject }) => {
  const { isPhone } = LayoutState();
  const { isSidebarOpen } = useOutletContext();

  const [selectedNav, setSelectedNav] = useState("board");
  const [topHeight, setTopHeight] = useState(115);
  const [projectMembers, setProjectMembers] = useState([]);
  const { organizationMembers } = UserState();

  const navs = [
    { id: 1, name: "overview", value: "Overview" },
    { id: 2, name: "board", value: "Board" },
    { id: 3, name: "backlog", value: "Backlog" },
    { id: 4, name: "milestones", value: "Milestones" },
  ];

  const components = {
    overview: (
      <Overview
        viewingProject={viewingProject}
        projectMembers={projectMembers}
      />
    ),
    board: <Board viewingProject={viewingProject} />,
    backlog: <Backlogs viewingProject={viewingProject} />,
    milestones: <Milestones viewingProject={viewingProject} />,
  };

  useEffect(() => {
    if (
      viewingProject?.sprints?.activeSprint === "None" &&
      selectedNav === "board"
    ) {
      setSelectedNav("backlog");
    }
  }, [viewingProject]);

  useEffect(() => {
    if (viewingProject?.id) {
      setProjectMembers(() =>
        organizationMembers.filter((member) => {
          return (
            member?.projectIds?.includes(viewingProject.id) ||
            member?.projectId === viewingProject.id
          );
        })
      );
    }
  }, [viewingProject, organizationMembers]);

  useEffect(() => {
    const headingElement = document.getElementById("project-page-heading");
    if (headingElement) {
      const heightToLeave =
        headingElement.clientHeight +
        headingElement.getBoundingClientRect().top;
      setTopHeight(heightToLeave);
    }
  }, []);

  return (
    <Box
      flex={1}
      marginRight={0}
      marginLeft={isSidebarOpen ? `200px` : "0px"}
      pointerEvents={isOpen ? "none" : "auto"}
      transition="margin-left 0.3s ease, pointer-events 0.3s ease"
      display={"flex"}
      flexDir={"column"}
      height={"100vh"}
      maxHeight={"100vh"}
      overflow="hidden"
    >
      <Heading
        my={0}
        py={3}
        ml={4}
        mb={3}
        as="h1"
        size="lg"
        fontWeight="bold"
        color="gray.700"
        id="project-page-heading"
      >
        {viewingProject?.projectName || "Project"}
      </Heading>
      <Navbar
        selectedNav={selectedNav}
        setSelectedNav={setSelectedNav}
        navs={navs}
      />
      <Box
        height={`calc(100vh - ${topHeight}px)`}
        width={"100%"}
        maxHeight={`calc(100vh-${topHeight}px)`}
        paddingY={2}
      >
        {components[selectedNav]}
      </Box>
    </Box>
  );
};

export default Main;
