import React from "react";
import { Box, Button, Flex, Text } from "@chakra-ui/react";
import PerfectScrollbar from "react-perfect-scrollbar";

export default function QueryList({ queries, onQueryClick }) {
  return (
    <Box overflowX="auto" width="100%">
      <PerfectScrollbar
        options={{
          suppressScrollY: true,
          useBothWheelAxes: false,
        }}
        style={{
          width: "100%",
          height: `calc(100% + 14px)`,
          paddingRight: "0px",
        }}
      >
        <Flex gap={2} minWidth="max-content">
          {queries.map((query) => (
            <Button
              key={query.id}
              onClick={() => onQueryClick(query)}
              variant="outline"
              size="md"
              px={4}
              py={2}
              height="auto"
              minH={10}
              borderRadius="full"
              borderWidth={2}
              borderColor="gray.200"
              bg="white"
              _hover={{
                bg: "gray.50",
                borderColor: "blue.500",
              }}
              _active={{
                bg: "gray.100",
              }}
              fontSize="sm"
              fontWeight="medium"
              whiteSpace="normal"
              textAlign="center"
            >
              {query.value}
            </Button>
          ))}
        </Flex>
      </PerfectScrollbar>
    </Box>
  );
}
