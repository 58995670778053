"use client";

import { Box, Button, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import SideBarContent from "./SideBarContent";
import { ArrowBackIcon } from "@chakra-ui/icons";

const PhoneSideBar = ({ isOpen, onOpen, onClose }) => {
  const [leaveSpaceAbove, setLeaveSpaceAbove] = useState("55");

  const handleResize = () => {
    const space =
      document.getElementsByClassName("header-container")[0]?.clientHeight;

    setLeaveSpaceAbove(space);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize(); // Call once on mount to set initial value

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Box
      position="fixed"
      // top={leaveSpaceAbove}
      left={0}
      width="100%"
      height={`calc(100% - ${leaveSpaceAbove}px)`}
      overflow="hidden"
      display={isOpen ? "flex" : "none"}
      flexDirection="column"
      className="phone-side-bar"
      zIndex={99}
      boxShadow="0 4px 12px rgba(0, 0, 0, 0.1)"
      transition="all 0.3s ease-in-out"
    >
      <Box
        bgColor="white"
        flex={1}
        display="flex"
        flexDirection="column"
        overflow="auto"
        p={2}
      >
        <SideBarContent onClose={onClose} />
      </Box>
    </Box>
  );
};

export default PhoneSideBar;
