import React from "react";
import {
  Box,
  VStack,
  Text,
  Heading,
  useColorModeValue,
  Flex,
  Badge,
  Button,
  useDisclosure,
  Icon,
  Divider,
} from "@chakra-ui/react";
import {
  EditIcon,
  CheckCircleIcon,
  WarningIcon,
  InfoIcon,
  LinkIcon,
} from "@chakra-ui/icons";
import { FaRobot, FaSlack } from "react-icons/fa";
import UpdateOrganizationModal from "./UpdateOrganizationModal";
import { UserState } from "../../Context/UserProvider";
import SlackConnectModal from "./SlackConnectModal";

const InfoCard = ({ title, value, icon }) => {
  const cardBg = useColorModeValue("white", "gray.700");
  const cardBorder = useColorModeValue("gray.200", "gray.600");

  return (
    <Box
      bg={cardBg}
      borderWidth="1px"
      borderColor={cardBorder}
      borderRadius="lg"
      p={4}
      shadow="md"
      transition="all 0.3s"
      _hover={{ transform: "translateY(-5px)", shadow: "lg" }}
      whiteSpace={"nowrap"}
      flex={1}
    >
      <Flex align="center" mb={2}>
        <Icon as={icon} boxSize={6} mr={2} color="blue.500" />
        <Text fontWeight="bold" fontSize="lg" whiteSpace={"nowrap"}>
          {title}
        </Text>
      </Flex>
      <Text fontSize="md">{value}</Text>
    </Box>
  );
};

const Overview = ({ viewingOrganization }) => {
  const { selectedOrganization } = UserState();
  const {
    isOpen: isUpdateOrganizationModalOpen,
    onOpen: openUpdateOrganizationModal,
    onClose: closeUpdateOrganizationModal,
  } = useDisclosure();
  const {
    isOpen: isSlackConnectModalOpen,
    onOpen: openSlackConnectModal,
    onClose: closeSlackConnectModal,
  } = useDisclosure();

  // const bgColor = useColorModeValue("gray.50", "gray.800");
  const textColor = useColorModeValue("gray.800", "white");

  const handleSlackConnectClicked = async () => {
    // TODO: Call API to verify organization
  };

  return (
    <Box borderRadius="lg" color={textColor} height={"100%"} width={"100%"}>
      <VStack align="stretch">
        <Flex justify="right" align="center" p={1} pt={3}>
          <Button
            leftIcon={<EditIcon />}
            colorScheme="blue"
            onClick={openUpdateOrganizationModal}
            size="sm"
          >
            Edit Organization
          </Button>
        </Flex>

        <Flex wrap="wrap" justify="space-between" gap={4} p={6}>
          <InfoCard
            title="Organization"
            value={selectedOrganization?.name}
            icon={InfoIcon}
          />
          <InfoCard
            title="Domain"
            value={selectedOrganization?.domain}
            icon={LinkIcon}
          />
          <InfoCard
            title="Assistant"
            value={selectedOrganization?.pmbotName}
            icon={FaRobot}
          />
          <InfoCard
            title="Verification Status"
            value={
              <Badge
                colorScheme={selectedOrganization?.isVerified ? "green" : "red"}
                fontSize="0.8em"
                p={1}
              >
                {selectedOrganization?.isVerified ? "Verified" : "Unverified"}
              </Badge>
            }
            icon={
              selectedOrganization?.isVerified ? CheckCircleIcon : WarningIcon
            }
          />
        </Flex>

        <Box p={6}>
          <Flex wrap="wrap" justify="space-between" gap={4}>
            <InfoCard
              title="Total Projects"
              value={selectedOrganization?.totalProjects}
              icon={InfoIcon}
            />
            <InfoCard
              title="Active Members"
              value={selectedOrganization?.membersCount}
              icon={InfoIcon}
            />
          </Flex>
        </Box>

        <Divider />

        <Box p={6}>
          <Heading size={"md"}>Connected Apps</Heading>
          <Box p={6} pl={2}>
            <Flex wrap="wrap" justify="space-between" gap={4}>
              <InfoCard
                title="Slack"
                value={
                  selectedOrganization?.isSlackConnected ? (
                    <Badge colorScheme={"green"} fontSize="0.8em" p={1}>
                      Connected
                    </Badge>
                  ) : (
                    <Button
                      size={"sm"}
                      colorScheme="blue"
                      onClick={openSlackConnectModal}
                    >
                      Connect Slack
                    </Button>
                  )
                }
                icon={FaSlack}
              />
            </Flex>
          </Box>
        </Box>
      </VStack>

      {isUpdateOrganizationModalOpen && (
        <UpdateOrganizationModal
          isOpen={isUpdateOrganizationModalOpen}
          onClose={closeUpdateOrganizationModal}
        />
      )}

      {isSlackConnectModalOpen && (
        <SlackConnectModal
          isOpen={isSlackConnectModalOpen}
          onClose={closeSlackConnectModal}
        />
      )}
    </Box>
  );
};

export default Overview;
