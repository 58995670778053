import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  Input,
  Box,
  FormHelperText,
  ModalHeader,
  useToast,
  Textarea,
  Text,
  VStack,
  HStack,
  Divider,
  Stack,
} from "@chakra-ui/react";
import { UserState } from "../../../Context/UserProvider";
import { LayoutState } from "../../../Context/LayoutProvider";
import { AuthState } from "../../../Context/AuthProvider";
import ProjectDropDown from "../Dropdowns/ProjectDropDown";
import PriorityDropDown, { PriorityIcon } from "../Dropdowns/PriorityDropDown";
import AssigneeDropDown from "../Dropdowns/AssigneeDropDown";
import ReporterDropDown from "../Dropdowns/ReporterDropDown";
import MilestoneDropDown from "../Dropdowns/MilestoneDropDown";
import useNotifications from "../../../hooks/useNotifications";
import { ServiceState } from "../../../Context/ServiceProvider";

const CreateMyTaskModal = ({
  isOpen,
  onClose,
  setAllTasks,
  project,
  processNewTask,
  providedSprintId = null,
}) => {
  const { isPhone, screenWidth } = LayoutState();
  const { selectedOrganization, createTask } = UserState();
  const { reloadUserMetrics } = AuthState();
  const [title, setTitle] = useState("");
  const [projectId, setProjectId] = useState("");
  const [reviewerId, setReviewerId] = useState("");
  const [description, setDescription] = useState("");
  const [assigneeId, setAssigneeId] = useState("");
  const [dueDate, setDueDate] = useState("");
  const [duration, setDuration] = useState(0);
  const [priority, setPriority] = useState("");
  const [milestone, setMilestone] = useState({ id: "", title: "" });
  const [sprintId, setSprintId] = useState(providedSprintId);
  const [sprintClick, setSprintClick] = useState("yes");
  const [isLoading, setIsLoading] = useState(false);
  const [isProjectDropDownOpen, setIsProjectDropDownOpen] = useState(false);
  const [isPriorityDropDownOpen, setIsPriorityDropDownOpen] = useState(false);
  const [isReporterDropDownOpen, setIsReporterDropDownOpen] = useState(false);
  const [isAssigneeDropDownOpen, setIsAssigneeDropDownOpen] = useState(false);

  const toast = useToast();

  const priorityStyle = {
    high: "red.500",
    medium: "orange.400",
    low: "green.500",
  };

  const priorityText = {
    high: "High",
    medium: "Medium",
    low: "Low",
  };

  const modifiedOnClose = () => {
    setTitle("");
    setDescription("");
    setProjectId("");
    setReviewerId("");
    setAssigneeId("");
    setDueDate("");
    setDuration(-1);
    setPriority("");
    setMilestone({ id: "", title: "" });
    setIsProjectDropDownOpen(false);
    onClose();
  };

  const handleCreateTask = async () => {
    try {
      setIsLoading(true);

      const taskData = {};
      if (projectId) taskData.projectId = projectId;
      if (selectedOrganization.id) taskData.orgId = selectedOrganization.id;
      if (assigneeId) taskData.assigneeId = assigneeId;
      if (title) taskData.title = title;
      if (description) taskData.description = description;
      if (reviewerId) taskData.reviewerId = reviewerId;
      if (dueDate) taskData.dueDate = dueDate;
      if (duration > 0) taskData.duration = duration;
      if (priority) taskData.priority = priority;
      if (milestone.id) taskData.milestone = milestone;
      if (sprintId) taskData.sprintId = sprintId;

      try {
        const newTask = await createTask(
          taskData,
          project ? true : false,
          setAllTasks
        );

        if (project && !setAllTasks) {
          processNewTask(newTask);
        }

        toast({
          title: "Task created successfully.",
          status: "success",
          duration: 2000,
          isClosable: true,
        });
        // reloadUserMetrics();
        onClose();
      } catch (error) {
        toast({
          title: "Task creation failed.",
          status: "error",
          duration: 2000,
          isClosable: true,
        });
      } finally {
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
      toast({
        title: "Exception occurred.",
        description: `${err.message}`,
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    }
  };

  const handleDurationChange = (e) => {
    let value = e.target.value;
    // Remove leading zeros before the decimal point
    value = value.replace(/^0+(?=\d)/, "");
    // Ensure only one decimal point
    value = value.replace(/\.+/g, ".");
    // Allow only numbers and one decimal point
    value = value.replace(/[^\d.]/g, "");
    // If the value is empty or just a decimal point, set it to "0"
    if (value === "" || value === ".") {
      value = "0";
    }
    setDuration(value);
  };

  const handleDueDateChange = (e) => {
    const selectedDate = e.target.value; // This will capture the date in 'YYYY-MM-DD' format
    setDueDate(selectedDate); // Update the state with the selected date
  };

  const getTodayDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0"); // Month is zero-based
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  useEffect(() => {
    if (providedSprintId) {
      setSprintId(providedSprintId);
    } else {
      if (sprintClick === "yes") {
        setSprintId(project?.sprints?.activeSprint);
      } else {
        setSprintId(project?.sprints?.backlogSprint);
      }
    }
  }, []);

  useEffect(() => {
    if (isOpen && !selectedOrganization) {
      toast({
        title: "Cannot create task",
        description: `An organization must be selected before creating a new task`,
        status: "error",
        duration: 2000,
        isClosable: true,
      });
      onClose();
    }
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} onClose={modifiedOnClose} isCentered size="4xl">
      <ModalOverlay />
      <ModalContent
        maxHeight="90vh"
        overflow="auto"
        borderRadius="lg"
        bg="white"
        boxShadow="xl"
        maxWidth={`${Math.min(0.8 * screenWidth, 900)}px`}
      >
        <ModalHeader
          color="black"
          borderTopLeftRadius="lg"
          borderTopRightRadius="lg"
          py={4}
        >
          <FormControl isRequired>
            <Input
              value={title}
              className="my-task-create-title"
              placeholder="Task Title"
              onChange={(e) => setTitle(e.target.value)}
              size="lg"
              border="none"
              fontSize="2xl"
              fontWeight="bold"
              _placeholder={{ color: "black.200" }}
              _focus={{ boxShadow: "none" }}
              autoFocus={false}
            />
          </FormControl>
        </ModalHeader>
        <ModalCloseButton zIndex={2} />
        <ModalBody>
          <VStack spacing={6} align="stretch" py={4}>
            <Stack
              direction={screenWidth < 950 ? "column" : "row"}
              spacing={4}
              wrap="wrap"
            >
              <FormControl isRequired flex={1} minW="200px">
                <ProjectDropDown
                  onClose={() => setIsProjectDropDownOpen(false)}
                  onOpen={() => setIsProjectDropDownOpen(true)}
                  isOpen={isProjectDropDownOpen}
                  setProjectId={setProjectId}
                  project={project}
                />
                <FormHelperText>Project</FormHelperText>
              </FormControl>

              <Stack flex={2} direction={screenWidth < 570 ? "column" : "row"}>
                <FormControl isRequired flex={1} minW="200px">
                  <AssigneeDropDown
                    onClose={() => setIsAssigneeDropDownOpen(false)}
                    onOpen={() => setIsAssigneeDropDownOpen(true)}
                    isOpen={isAssigneeDropDownOpen}
                    setAssigneeId={setAssigneeId}
                    projectId={projectId}
                    reviewerId={reviewerId}
                  />
                  <FormHelperText>Assignee</FormHelperText>
                </FormControl>
                <FormControl isRequired flex={1} minW="200px">
                  <ReporterDropDown
                    onClose={() => setIsReporterDropDownOpen(false)}
                    onOpen={() => setIsReporterDropDownOpen(true)}
                    isOpen={isReporterDropDownOpen}
                    setReviewerId={setReviewerId}
                    projectId={projectId}
                    assigneeId={assigneeId}
                  />
                  <FormHelperText>Reviewer</FormHelperText>
                </FormControl>
              </Stack>
            </Stack>

            <FormControl isRequired>
              <Textarea
                value={description}
                placeholder="Task Description"
                onChange={(e) => setDescription(e.target.value)}
                minH="200px"
                resize="vertical"
                bgColor={"gray.100"}
                borderColor="gray.300"
                _hover={{ borderColor: "gray.400" }}
                _focus={{ borderColor: "blue.500", boxShadow: "outline" }}
              />
            </FormControl>

            <Divider />

            <HStack spacing={4} wrap="wrap">
              <FormControl flex={1} minW="150px">
                <Input
                  value={duration}
                  bgColor={"gray.100"}
                  min={0}
                  placeholder="Duration"
                  onChange={handleDurationChange}
                  type="text"
                  inputMode="decimal"
                  step="0.1"
                />
                <FormHelperText>Duration (in hours)</FormHelperText>
              </FormControl>
              {/* jnj */}

              <FormControl flex={1} minW="150px">
                <Input
                  value={dueDate}
                  bgColor="gray.100"
                  placeholder="Select Due Date"
                  onChange={handleDueDateChange}
                  type="date"
                  min={getTodayDate()} // Ensure the date is >= today
                />
                <FormHelperText>Due Date</FormHelperText>
              </FormControl>
            </HStack>

            <HStack spacing={4} wrap="wrap">
              <FormControl flex={1} minW="150px">
                <MilestoneDropDown
                  projectId={projectId}
                  milestone={milestone}
                  setMilestone={setMilestone}
                />
                <FormHelperText>Milestone</FormHelperText>
              </FormControl>

              <FormControl flex={1} minW="150px">
                <PriorityDropDown
                  onClose={() => setIsPriorityDropDownOpen(false)}
                  onOpen={() => setIsPriorityDropDownOpen(true)}
                  isOpen={isPriorityDropDownOpen}
                  priority={priority}
                  setPriority={setPriority}
                >
                  <Button
                    w="full"
                    bg={"gray.100"}
                    color={priority ? "black" : "gray.800"}
                    _hover={{ opacity: 0.8 }}
                    fontWeight={"400"}
                    textAlign={"left"}
                  >
                    <Box>
                      {priority ? (
                        <Box display={"flex"} alignItems={"center"} gap={1}>
                          <PriorityIcon priority={priority} />
                          <Text color={priorityStyle[priority]}>
                            {priorityText[priority]}
                          </Text>
                        </Box>
                      ) : (
                        <Text color={"gray.500"}>Select Priority</Text>
                      )}
                    </Box>
                  </Button>
                </PriorityDropDown>
                <FormHelperText>Priority</FormHelperText>
              </FormControl>

              {/* kk */}
            </HStack>

            {!providedSprintId &&
              project?.sprints?.activeSprint &&
              project.sprints.activeSprint !== "None" && (
                <Box
                  bg="gray.50"
                  p={4}
                  borderRadius="md"
                  border="1px"
                  borderColor="gray.200"
                >
                  <Text fontWeight="medium" mb={2}>
                    Add to active sprint?
                  </Text>
                  <HStack>
                    <Button
                      size="sm"
                      colorScheme={sprintClick === "yes" ? "green" : "gray"}
                      onClick={() => {
                        setSprintClick("yes");
                        setSprintId(project?.sprints?.activeSprint);
                      }}
                    >
                      Yes
                    </Button>
                    <Button
                      size="sm"
                      colorScheme={sprintClick === "no" ? "red" : "gray"}
                      onClick={() => {
                        setSprintId(project?.sprints?.backlogSprint);
                        setSprintClick("no");
                      }}
                    >
                      No
                    </Button>
                  </HStack>
                </Box>
              )}

            <Button
              isLoading={isLoading}
              loadingText="Creating Task"
              color={"gray.500"}
              bgColor={"blue.100"}
              size="lg"
              _hover={{ bgColor: "blue.400", color: "white" }}
              onClick={handleCreateTask}
            >
              Create Task
            </Button>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default CreateMyTaskModal;
